import img1 from '../assets/Insurance/corporates/1.png'
import img2 from '../assets/Insurance/corporates/2.png'
import img3 from '../assets/Insurance/corporates/3.png'
import img4 from '../assets/Insurance/corporates/4.png'
import img5 from '../assets/Insurance/corporates/5.png'
import img6 from '../assets/Insurance/corporates/6.png'
import img7 from '../assets/Insurance/corporates/7.png'
import img8 from '../assets/Insurance/corporates/8.png'
import img9 from '../assets/Insurance/corporates/9.png'
import img10 from '../assets/Insurance/corporates/10.png'
import img11 from '../assets/Insurance/corporates/11.png'
import img12 from '../assets/Insurance/corporates/12.png'
import img13 from '../assets/Insurance/corporates/13.png'
import img14 from '../assets/Insurance/corporates/14.png'
import img15 from '../assets/Insurance/corporates/15.png'
import img16 from '../assets/Insurance/corporates/16.png'
import img17 from '../assets/Insurance/corporates/17.png'

export const InsureAssets = [
    {
        id: 1,
        img: img1
    },
    {
        id: 2,
        img: img2
    },
    {
        id: 3,
        img: img3
    },
    {
        id: 4,
        img: img4
    },
    {
        id: 5,
        img: img5
    },
    {
        id: 6,
        img: img6
    },
    {
        id: 7,
        img: img7
    },
    {
        id: 8,
        img: img8
    },
    {
        id: 9,
        img: img9
    },
    {
        id: 10,
        img: img10
    },
    {
        id: 11,
        img: img11
    },
    {
        id: 12,
        img: img12
    },
    {
        id: 13,
        img: img13
    },
    {
        id: 14,
        img: img14
    },
    {
        id: 15,
        img: img15
    },
    {
        id: 16,
        img: img16
    },
    {
        id: 17,
        img: img17
    }
]