import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { videosRoute } from "../../utils/Endpoint";
import VideoCard from "../../components/video/VideoCard";

const Videos = () => {
  const [videos, setVideos] = useState([]);

  const getVideos = async () => {
    try {
      const res = await axios.get(videosRoute);
      if (res.status === 200) {
        setVideos(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <div className="max-w-common mx-auto mt-36 p-4 flex flex-col items-center ">
      {/* <h3 className='text-4xl max-md:text-2xl font-extralight max-md:font-light text-secondary'>Videos</h3> */}

      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 items-center justify-evenly">
        {videos?.map((item, index) => (
          <VideoCard key={index} video={item} />
        ))}
      </div>
    </div>
  );
};

export default Videos;
