import React from 'react'
import CorporateCard from './CorporateCard'
import { InsureAssets } from '../../data/insurace'

function Corporates() {
    console.log(InsureAssets)
    return (
        <div className='grid grid-cols-2 md:grid-cols-4  gap-5 lg:gap-10 mt-10 lg:mt-18  p-3 lg:p-0'>
            {
                InsureAssets?.map((image, index) => (
                    <CorporateCard key={index} image={image?.img} />
                ))
            }
        </div>
    )
}

export default Corporates
