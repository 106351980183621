import React, { useEffect, useState } from "react";
import TestCard from "./TestCard";
// import { tests } from "../../data/tests";
import axios from "../../api/axios";
import { checkupsRoute } from "../../utils/Endpoint";

const Test = () => {
  const [checkups, setCheckups] = useState([]);

  const getcheckups = async () => {
    try {
      const res = await axios.get(checkupsRoute)

      if (res.status === 200) {
        const result = res.data.result;
        setCheckups(result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => { getcheckups() }, []);

  return (
    <div className="w-full flex flex-wrap gap-5 items-start">
      {checkups?.map((item, i) => (
        <TestCard
          key={i}
          name={item?.title}
          price={item?.price}
          list={item?.tests}
        />
      ))}
    </div>
  );
};

export default Test;
