import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { blogsRoute } from '../../utils/Endpoint'
import BlogCard from '../home/blogs/BlogCard'
import BlogCardFullVew from './BlogCardFullVew'

const BlogListing = () => {
    const [blogs, setBlogs] = useState([])


    const getBlogs = async () => {
        try {
            const res = await axios.get(blogsRoute)

            if (res.status === 200) {
                setBlogs(res.data.blogs)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBlogs()
    }, [])

    return (
        <div className='max-w-common mx-auto pb-20 p-3 lg:p-0'>
            <div className="w-fit grid grid-cols-1 md:grid-cols-3 gap-5 lg:gap-10">
                {blogs.slice(0, 5).map((item) => (
                    <BlogCardFullVew key={item?._id} data={item} />
                ))}
            </div>
        </div>
    )
}

export default BlogListing
