import React, { useEffect, useRef } from "react";
import NavBar from "./nav/NavBar";
import Footer from "./footers/Footer";
import { useLocation } from "react-router-dom";
import CallBtn from "./floats/CallBtn";
import DocFloatIcon from "../components/floats/DocFloatIcon";
import CallUsFloat from "../components/floats/CallUsFloat";
import Lenis from 'lenis'
import EbookFloat from "./floats/EbookFloat";

const Layout = ({ children }) => {
  const location = useLocation();
  const authroutes = ["sign-up", "login", "reports"];
  const isAuthRoute = authroutes.some((route) =>
    location.pathname.includes(route)
  );

  useEffect(() => {
    // Initialize Lenis
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

  }, []);
  return (
    <div className="relative">
      <nav className="">
        <NavBar />
      </nav>
      <section className="overflow-hidden ">{children}</section>
      <footer className={`${isAuthRoute ? "mt-0" : "mt-14 md:mt-28"}  bg-[#F6F6F8]`}>
        <Footer />
      </footer>

      <div className="fixed bottom-10 lg:bottom-16 right-5 lg:right-12 z-40 ">
        <div className="flex flex-col gap-8 lg:gap-14">
          <CallUsFloat />
          <DocFloatIcon />
          <CallBtn />
          <EbookFloat />
        </div>

      </div>

    </div>
  );
};

export default Layout;
