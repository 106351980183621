import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from '../../api/axios';
import { departmentsRoute, doctorsRoute } from '../../utils/Endpoint';

const FindDoctorFilter = ({ start, setStart, setSelectedOptions, mode, handleScrollToSection }) => {
  const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const [departments, setDepartments] = useState([])
  const [doctors, setDoctors] = useState([])

  const getDepartments = async () => {
    try {
      const res = await axios.get(departmentsRoute)

      if (res.status === 200) {
        const result = res.data?.result

        if (result?.length) {
          const newArray = result?.map((item, index) => {
            return {
              value: item?.dept_id,
              label: item?.dept_name
            }
          })

          setDepartments(newArray)
        }
        else {
          setDepartments([])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDoctors = async () => {
    try {
      const res = await axios.get(doctorsRoute)

      if (res.status === 200) {
        const result = res.data?.result

        if (result?.length) {
          const newArray = result?.map((item, index) => {
            return {
              value: item?.doctor_id,
              label: item?.doctor_name
            }
          })

          setDoctors(newArray)
        }
        else {
          setDoctors([])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (mode === "department") {
      getDepartments()
    }
    else if (mode === "name") {
      getDoctors()
    }
  }, [mode])

  const handleSelectChange = (options) => {
    console.log({ options })
    if (options?.length > 0) {
      const values = options?.map((item) => item?.value)
      setSelectedOptions(JSON.stringify(values));
    }
    else {
      setSelectedOptions([])
    }
  };

  return (
    <div className='flex flex-col items-start  lg:p-3 px-4'>
      <div className='text-left'>
        <p className='opacity-50 text-sm'>Filters applied:</p><br />
        <p className='text-left pb-2 text-[#000000] '>Sorting by:{" "} 
          <span className='font-semibold capitalize '>
            {mode}
        </span> 
          </p>
        {
          start
          &&
          <p className='mb-10'>Name starting with letter <span className='font-semibold'>{start}</span></p>
        }

        <div>

          {/* <div className="relative inline-block ">
            <div>
              <button type="button" className="inline-flex border border-gray border-opacity-20 lg:w-[250px] w-[340px] justify-center gap-x-1.5 rounded-md mb-6
        bg-white px-3 py-2 text-md font-semibold text-primary_color lg:mr-auto
        " id="menu-button" aria-expanded={isOpen} aria-haspopup="true" onClick={toggleDropdown}>
                Select Departments to filter
                <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          {isOpen && (
            <div className="absolute z-10 w-40 rounded-md text-center bg-white shadow-lg ring-1
   ring-black ring-opacity-5 focus:outline-none" role="menu" aria-labelledby="menu-button" tabIndex={-1}>
              <div className="py-1" role="none">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-0">Account settings</a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-1">Support</a>
              </div>
            </div>
          )} */}

          <div className='mb-6'>
            <Select options={mode === "department" ? departments : doctors} isMulti={true} onChange={handleSelectChange} />

          </div>

          {/* <div className='flex flex-row gap-5 mb-10 '>
            <button className='bg-primary_color w-40 rounded-lg pb-2 flex flex-row justify-center pt-2 gap-2 '>
              <Image src={oncology1} alt="Oncology" width={12} height={12} className='pt-1' />
              <span className='text-white text-sm'>Oncology</span></button>
            <button className='bg-primary_color w-40 rounded-lg flex flex-row justify-center pt-2 gap-2'>
              <Image src={ortho1} alt="Orthopaedic" width={10} height={10} className='pt-1' />
              <span className='text-white text-sm'>Orthopaedic</span>
            </button>
          </div> */}

          <span className='opacity-50 pb-10'>Filter by First name:</span>
          <div className="grid grid-cols-5 w-full lg:w-80 gap-y-4 lg:mt-10 mt-6  mb-4 lg:mb-10 items-start justify-center">
            <button
              onClick={() => setStart("")}
              className={`lg:w-12 lg:h-12 w-14 h-14 rounded-full border border-gray text-black flex items-center justify-center text-lg font-bold 
          ${start === "" ? 'bg-primary_color text-white' : 'bg-white'}`}
            >
              All
            </button>
            {alphabets.map((letter) => (
              <button
                key={letter}
                onClick={() => setStart(letter)}
                className={`lg:w-12 lg:h-12 w-14 h-14 rounded-full border border-gray text-black flex items-center justify-center text-lg font-bold 
          ${letter === start ? 'bg-primary_color text-white' : 'bg-white'}`}
              >
                {letter}
              </button>
            ))}
          </div>

          <div
          onClick={handleScrollToSection}
          className="flex items-center space-x-3 mb-6">
            {/* Text */}
            <span className="text-lg text-gray lg:ml-10">Go to top</span>

            {/* Circle with Arrow */}
            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-primary_color">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="1 1 24 24"
                fill="white"
                className="w-8 h-10 justify-center"
              >
                <path
                  fillRule="evenodd"
                  d="M18.746 15.204l0.742-0.742-6.379-6.379-6.378 6.379 0.742 0.742 5.112-5.112v12.727h1.049v-12.727z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div>

          </div>
        </div>

      </div>


    </div>
  )
}

export default FindDoctorFilter
