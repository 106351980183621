import './report.css';
import movingLogo from '../../assets/logo/moving_logo.png';
import { useState } from 'react';
import axios from '../../api/axios';
import { fetchLabReport, fetchRadiologyReport } from '../../utils/Endpoint';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const Reports = () => {
    const { t } = useTranslation();
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
        otpTxtN: "",
        otpCheck: 0
    })

    const handleChange = (e) => {
        const { name, value } = e.target;

        setCredentials((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const [labLoader, setLabLoader] = useState(false);
    const [radiologyLoader, setRadiologyLoader] = useState(false);

    const createUrlForPdf = (base64Pdf) => {
        const byteCharacters = atob(base64Pdf);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank', 'noopener,noreferrer');

        setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
    }

    const handleOpenLabPdf = async () => {
        setLabLoader(true);

        try {
            const response = await axios.post(fetchLabReport,
                {
                    data: credentials
                }
            );

            if (response?.data?.status === "success") {
                const base64Pdf = response?.data?.data;

                if (base64Pdf) {
                    createUrlForPdf(base64Pdf)
                }
                else {
                    toast.info('Sorry, No data found')
                }
            }
            else {
                toast.error('Failed to fetch Lab Report')
            }

        } catch (error) {
            console.error('Error fetching the PDF:', error);
            toast.error('Error fetching Lab Report')
        }

        setLabLoader(false);
    };

    const handleOpenRadiologyPdf = async () => {
        setRadiologyLoader(true);

        try {
            const response = await axios.post(fetchRadiologyReport,
                {
                    data: credentials
                }
            );

            if (response?.data?.status === "success") {
                const base64Pdf = response?.data?.data;

                if (base64Pdf) {
                    createUrlForPdf(base64Pdf)
                }
                else {
                    toast.info('Sorry, No data found')
                }
            }
            else {
                toast.error('Failed to fetch Radiology Report')
            }

        } catch (error) {
            console.error('Error fetching the PDF:', error);
            toast.error('Error fetching Radiology Report')
        }

        setRadiologyLoader(false);
    };

    return (
        <div className={`w-full h-screen bg-[#45273c] maindiv flex mt-32`}>
            <div className="hidden sm:w-1/2 h-screen sm:flex flex-col items-center justify-center gap-5 relative">
                <div className={`w-[350px] h-[400px] bg-transparent chip1 relative`}>
                    <img
                        src={movingLogo}
                        alt="Moving Logo"
                        width={120}
                        height={120}
                        className="absolute left-6 -top-6 opacity-30"
                    />
                    <img
                        src={movingLogo}
                        alt="Moving Logo"
                        width={40}
                        height={40}
                        className="absolute left-4 top-[120px] opacity-30"
                    />
                    <img
                        src={movingLogo}
                        alt="Moving Logo"
                        width={80}
                        height={80}
                        className="absolute left-3 top-[180px] opacity-30"
                    />
                    <img
                        src={movingLogo}
                        alt="Moving Logo"
                        width={180}
                        height={180}
                        className="absolute left-16 top-[240px] opacity-30"
                    />
                </div>

                <div className={`w-[350px] h-[400px] bg-transparent absolute chip2 `}></div>
                <div className={`w-[350px] h-[300px] bg-transparent absolute chip3 `}></div>
                <div className={`w-[260px] h-[400px] bg-transparent absolute imgdiv `}></div>
            </div>

            <div className="w-full sm:w-1/2 h-screen bg-[#FCFEFF] sm:rounded-tl-xl sm:rounded-bl-xl flex flex-col items-center justify-center gap-5 relative overflow-clip">
                <h1 className="text-2xl font-bold">Get Reports </h1>

                <div className="w-[70%] h-auto flex flex-col items-start">
                    <label className="text-[#7C838A]">Username</label>
                    <input
                        name="username"
                        value={credentials?.username}
                        onChange={handleChange}
                        placeholder="Enter username"
                        className="bg-[#dcdedf] w-full h-[7vh] outline-none p-5 rounded-xl"
                    />
                </div>

                <div className="w-[70%] h-auto flex flex-col items-start">
                    <label className="text-[#7C838A]">Password</label>
                    <input
                        name="password"
                        value={credentials?.password}
                        onChange={handleChange}
                        placeholder="Enter password"
                        className="bg-[#dcdedf] w-full h-[7vh] outline-none p-5 rounded-xl"
                    />
                </div>

                <div className='w-[70%] mt-8 flex flex-col sm:flex-row gap-8 sm:gap-4 justify-between items-center'>
                    <button
                        onClick={handleOpenLabPdf}
                        disabled={labLoader}
                        className="w-full sm:w-fit bg-primary_color text-white p-3 rounded-lg">
                        {labLoader ? t("loading") : "Lab report"}
                    </button>

                    <button
                        onClick={handleOpenRadiologyPdf}
                        disabled={radiologyLoader}
                        className="w-full sm:w-fit bg-primary_color text-white p-3 rounded-lg">
                        {radiologyLoader ? t("loading") : "Radiology report"}
                    </button>


                </div>


                <img
                    src={movingLogo}
                    alt="Moving Logo"
                    width={200}
                    height={200}
                    className="absolute -right-12 bottom-10 opacity-30"
                />

                <img
                    src={movingLogo}
                    alt="Moving Logo"
                    width={160}
                    height={160}
                    className="absolute -left-16 -top-6 opacity-30"
                />
                <img
                    src={movingLogo}
                    alt="Moving Logo"
                    width={100}
                    height={100}
                    className="absolute left-[130px] top-6 opacity-30"
                />
                <img
                    src={movingLogo}
                    alt="Moving Logo"
                    width={60}
                    height={60}
                    className="absolute left-0 top-[150px] opacity-30"
                />
                <img
                    src={movingLogo}
                    alt="Moving Logo"
                    width={100}
                    height={100}
                    className="absolute -left-10 bottom-[100px] opacity-30"
                />
                <img
                    src={movingLogo}
                    alt="Moving Logo"
                    width={60}
                    height={60}
                    className="absolute left-8 bottom-5 opacity-30"
                />
            </div>
        </div>
    );
};

export default Reports;