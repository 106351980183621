import React from 'react'

function CorporateCard({ image }) {
    return (
        <div className='rounded-2xl flex items-center justify-center shadow-[-6px_6px_60px_0px_rgba(0,0,0,0.2)] lg:w-[300px] lg:h-[150px]'>
            <img className='object-cover rounded-xl lg:w-[300px] lg:h-[150px]' src={image} alt="coverImage" />
        </div>
    )
}

export default CorporateCard
