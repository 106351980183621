import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { PAYU_BASE_URL, confirmBookingRoute, genderListRoute, patientDetailRoute, payLaterRoute, paymentDetailsRoute, reactAppURL, redirectToGatewayRoute } from '../../utils/Endpoint';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';


const DetailPage = ({ detail, setDetail, changeHandler, setOpen, BackToIntialState, setOpenApptModal,
    setApptData }) => {

    // Turn "payNowDisabled" to false to activate Pay Now button and getPaymentDetails();
    const payNowDisabled = true;

    const [payDetails, setPayDetails] = useState({})

    const [genderlist, setGenderList] = useState([])

    const [confirmedData, setConfirmedData] = useState({})

    const [page, setPage] = useState(1)

    const location = useLocation()

    const currentRoute = reactAppURL + location.pathname;


    const payLaterFunc = async (txnId) => {
        if (!txnId) {

            console.log('Transaction ID is required');;
            return
        }

        try {
            const res = await axios.post(payLaterRoute, {
                data: { transaction_id: txnId, is_free_visit: false }
            });

            if (res?.data?.status === "success") {
                BackToIntialState()
                setApptData(res?.data?.data)
                setOpenApptModal(true)
            } else {
                throw new Error(res?.data?.msg);
            }
        } catch (error) {
            console.log(error);

        }
    };

    const GetPayDetails = async (txnId) => {


        if (!txnId) {

            // console.log('Transaction ID is required');
            return
        }

        try {
            const res = await axios.post(redirectToGatewayRoute,
                { txnId, currentRoute }
            )

            if (res?.data?.status === "success") {
                setPayDetails(res?.data?.data)

            } else {
                throw new Error(res?.data?.msg)
            }

        } catch (error) {
            console.log(error);

        }


    }


    const ConfirmBooking = () => {

        axios.post(confirmBookingRoute,
            {
                data: {
                    ...detail,
                    is_web_booking: true,
                    telemedicine: false,
                    telemedicine_mobile_user: "",
                }
            }
        )
            .then((res) => {
                if (res?.data?.status === "success") {
                    toast.success('Booking Confirmed')

                    setConfirmedData(res.data?.data);

                    setPage(2)

                    if (res.data?.data?.transaction_id && !payNowDisabled) {

                        GetPayDetails(res.data?.data?.transaction_id)

                    }

                } else {
                    toast.error(res?.data?.msg)

                }
            })
            .catch((error) => {
                console.log(error)
                toast.error('Failed to confirm booking, Try again')
            })

    }

    const GetGenders = async () => {
        try {
            const res = await axios.post(genderListRoute)

            if (res?.data?.status === "success") {
                const list = res?.data?.data || [];

                setGenderList([...list])
            }

        } catch (error) {
            console.log(error)
        }
    }

    const GetPatientDetails = async () => {
        try {
            const res = await axios.post(patientDetailRoute, {
                data: {
                    visit_id: detail?.visit_id,
                    otp: detail?.otp,
                    mobile_number: detail?.mobile_number
                }
            })

            if (res?.data?.status === "success") {
                setDetail((prev) => ({
                    ...prev,
                    op_number: res?.data?.data[0]?.op_number,
                    age: res?.data?.data[0]?.age,
                    patient_name: res?.data?.data[0]?.patient_name,
                    address: res?.data?.data[0]?.address,
                    gender: res?.data?.data[0]?.genderId,
                }))
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetGenders();
        GetPatientDetails();
        GetPayDetails();
    }, [])

    return (
        <div className='flex flex-col gap-2 sm:gap-5'>

            {
                page === 1
                &&
                <>
                    <h4 className='text-md font-medium text-primary_color'>
                        Contact Info
                    </h4>
                    <p className='text-sm font-medium'>Please enter your details to finish booking </p>

                    <TextField onChange={changeHandler} value={detail?.patient_name} size='small' id="outlined-search" label="Full name" type="text" name='patient_name' required />
                    <TextField onChange={changeHandler} value={detail?.email} size='small' id="outlined-search" label="Email" type="email" name='email' />

                    <TextField onChange={changeHandler} value={detail?.age} size='small' id="outlined-search" label="Age" type="number" name='age'  />

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={detail?.gender}
                            label="gender"
                            onChange={changeHandler}
                            name='gender'
                            required
                        >
                            {
                                genderlist?.length > 0 &&

                                genderlist.map((item, i) => (
                                    <MenuItem key={i} value={item?.id}>{item?.gender}</MenuItem>

                                ))
                            }

                        </Select>
                    </FormControl>

                    <TextField onChange={changeHandler} value={detail?.address} size='small' id="outlined-search"
                        label="Address" type="text" name='address' multiline rows={2} />


                    <div className='w-full flex flex-col sm:flex-row gap-2'>
                        <button
                            onClick={ConfirmBooking}
                            className='w-full border-none py-2 rounded-lg text-white bg-primary_color'>
                            Confirm Booking
                        </button>
                        <button
                            onClick={BackToIntialState}
                            className='w-full py-2 rounded-lg text-primary_color border border-primary_color'>
                            Cancel
                        </button>

                    </div>
                </>
            }

            {
                page === 2
                &&
                <div className='flex flex-col p-2 gap-2 sm:gap-5 '>
                    <h2 className='text-primary_color text-xl self-center'>Booking Details</h2>

                    <div className='flex flex-col items-start gap-3 p-2'>
                        <p className='capitalize'>
                            Name:
                            <span className='text-primary_color'> {confirmedData?.patient_name ?? 'NIL'} </span>
                        </p>
                        <p className='capitalize'>
                            Token:
                            <span className='text-primary_color'> {confirmedData?.tokenNo ?? 'NIL'} </span>
                        </p>

                        <p className='capitalize'>
                            Amount:
                            <span className='text-primary_color'>
                                {confirmedData?.net_amount ? ` ₹ ${confirmedData?.net_amount}` : ' NIL'}
                            </span>
                        </p>

                    </div>

                    <form action={PAYU_BASE_URL} method='post'>
                        <input type="hidden" name="key" value={payDetails?.key} />
                        <input type="hidden" name="txnid" value={payDetails?.txnid} />
                        <input type="hidden" name="productinfo" value={payDetails?.productinfo} />
                        <input type="hidden" name="amount" value={payDetails?.amount} />
                        <input type="hidden" name="email" value={payDetails?.email} />
                        <input type="hidden" name="firstname" value={payDetails?.firstname} />
                        <input type="hidden" name="surl" value={payDetails?.surl} />
                        <input type="hidden" name="furl" value={payDetails?.furl} />
                        <input type="hidden" name="phone" value={payDetails?.phone} />
                        <input type="hidden" name="hash" value={payDetails?.hash} />

                        <div className='w-full flex justify-between '>
                            <button
                                onClick={() => payLaterFunc(confirmedData?.transaction_id)}
                                className='border border-primary_color text-primary_color rounded-lg p-2'
                                type='button'
                            >
                                Pay Later
                            </button>

                            <button
                                disabled={payNowDisabled}
                                type='submit'
                                className='bg-primary_color text-white rounded-lg p-2'
                            >
                                Pay Now
                            </button>
                        </div>
                    </form>


                </div>
            }

        </div>
    )
}

export default DetailPage