import React from "react";
import "./Department.css";

const DepartmentCard = ({ data }) => {
  return (
    <div
      id="department"
      className="w-[295px] max-md:w-full rounded-2xl relative shadow-xl cursor-pointer overflow-hidden"
    >
      <img
        src={data?.banner?.location}
        alt="cover"
        className="w-[350px] max-md:w-full h-[230px] max-md:h-[200px] object-cover rounded-2xl ease-in-out duration-300 "
      />
      <div className="w-full h-full bg-black/40 absolute z-10 top-0 rounded-2xl flex flex-col  items-center justify-center gap-3">
        <img src={data?.logo?.location} alt="icon" className="w-14" />
        <div className="text-white font-bold text-xl">{data?.dept_name}</div>
      </div>
    </div>
  );
};

export default DepartmentCard;
