import React, { useEffect, useState } from "react";
import TopBanner from "../components/common/TopBanner";
import image from "../assets/department/departments_banner_bg.jpg";
import DepartmentCard from "../components/home/department/DepartmentCard";
import { department } from "../components/home/department/departmentData";
import { BsFileMedicalFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import axios from "../api/axios";
import { departmentsRoute } from "../utils/Endpoint";
import useBanner from "../hooks/useBanner";
function DepartmentsPage() {
  const [search, setSearch] = useState("");
  const [departments, setDepartments] = useState([]);

  const getDepts = async () => {
    try {
      const res = await axios.get(`${departmentsRoute}?search=${search}`)
      if (res.status === 200) {
        setDepartments(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDepts()
  }, [search])

  const Search = () => {
    const filterData = department.filter((item) =>
      item?.title.toLowerCase().includes(search.toLowerCase())
    );
    setDepartments(filterData);
  };

  const currentBanner = useBanner('departments')

  return (
    <div className="flex items-center flex-col">
      <TopBanner image={currentBanner?.image?.location ?? image}
        title={currentBanner?.title ?? "Our Departments"} />
      <div className="mt-28 max-md:mt-20 w-fit relative bottom-8">
        <input
          className="px-14 py-4 w-[460px] max-md:w-[310px] border-2 border-primary_color border-opacity-50 rounded-lg focus:outline-none"
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Department"
        />
        <div
          className="pl-3"
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <BsFileMedicalFill size={32} className=" text-primary_color" />
        </div>
        <div
          className="pe-3"
          style={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
          }}
        >
          <CiSearch onClick={getDepts} size={32} className="cursor-pointer text-primary_color" />
        </div>
      </div>
      <div className="max-w-common mx-auto p-3 md:mt-5">
        <div className="w-full flex flex-wrap max-md:grid max-md:grid-cols-2 items-center gap-5 max-md:gap-3 ">
          {departments?.map((item) => (
            <Link to={`/departments/detailed-view/${item?._id}`}>
              <DepartmentCard key={item?._id} data={item} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DepartmentsPage;
