import React, { useEffect, useRef, useState } from 'react';
import TopPart from '../../components/findadoctor/TopPart';
// import TopPart from '@/components/findadoctor/TopPart';
import FindDoctorFilter from '../../components/findadoctor/FindDoctorFilter';
import SearchBar from '../../components/findadoctor/SearchBar';
import FindDoctorCard from '../../components/findadoctor/FindDoctorCard';
import Pagination from '../../components/findadoctor/Pagination';
import Modal from '../../components/common/Modal';
import ModalBox from '../../components/findadoctor/ModalBox';
import DeptWiseDocsGrid from '../../components/findadoctor/DeptWiseDocsGrid';
import axios from '../../api/axios';
import { doctorsRoute } from '../../utils/Endpoint';
import DocPrimeCard from '../../components/findadoctor/DocPrimeCard';
import { ScaleLoader } from 'react-spinners';

const FindDoctor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState("name")

  const [page, setPage] = useState(1)
  const [entries, setEntries] = useState(2)

  const [docGrids, setDocGrids] = useState([])
  const [search, setSearch] = useState("")
  const [deptId, setDeptId] = useState("")
  const [deptStart, setDeptStart] = useState("")
  const [selectedDepts, setSelectedDepts] = useState([])

  const [doctors, setDoctors] = useState([])
  const [docStart, setDocStart] = useState("")
  const [selectedDocs, setSelectedDocs] = useState([])
  const [loading, setLoading] = useState(true);


  const getDocGrids = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${doctorsRoute}?search=${search}&department_id=${deptId}&deptstart=${deptStart}&selecteddepts=${selectedDepts}`)

      if (res.status === 200) {
        const result = res.data?.result;
        const docGrids = []
        for (const doc of result) {
          const existingGridIndex = docGrids.findIndex(item => item?.department_name === doc?.department_name)
          if (existingGridIndex === -1) {
            const newGrid = {
              department_name: doc?.department_name,
              list: [doc]
            }

            docGrids.push(newGrid)
          }
          else {
            docGrids[existingGridIndex]?.list?.push(doc)
          }
        }

        setDocGrids(docGrids)

      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false);
    }
  }

  const getDoctors = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${doctorsRoute}?search=${search}&department_id=${deptId}&docstart=${docStart}&selecteddocs=${selectedDocs}`)

      if (res.status === 200) {
        const result = res.data?.result || [];

        setDoctors(result)

      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false);
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchBySymptomsClick = () => {
    handleOpenModal();
  };

  useEffect(() => {
    if (mode === "name") {
      getDoctors()
    }
    else {
      getDocGrids()

    }
  }, [search, deptId, deptStart, selectedDepts, mode, docStart, selectedDocs])

  console.log({ docGrids })

  const removeFilters = () => {
    setSearch("")
    setDeptStart("")
    setDeptId("")
  }

  const targetRef = useRef(null);

  const handleScrollToSection = () => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth', 
      block: 'start',    
    });
  };

  return (
    <div ref={targetRef} className=' '>
      <TopPart  onSearchBySymptomsClick={handleSearchBySymptomsClick} />

      <div className="flex lg:flex-row flex-col lg:gap-10 gap-4 w-full mt-2 max-w-common mx-auto ">
        <section>
          {
            mode === "department"
            &&
            <FindDoctorFilter start={deptStart} setStart={setDeptStart} setSelectedOptions={setSelectedDepts} mode={mode}
             handleScrollToSection={handleScrollToSection} />
          }
          {
            mode === "name"
            &&
            <FindDoctorFilter start={docStart} setStart={setDocStart} setSelectedOptions={setSelectedDocs} mode={mode} 
            handleScrollToSection={handleScrollToSection} />
          }
        </section>
        <section>
          <SearchBar search={search} setSearch={setSearch} removeFilters={removeFilters} mode={mode} setMode={setMode} />

          <div className='lg:p-3 mt-2 max-w-common mx-auto px-4'>
            <div className='lg:mb-2'>
              {
                search?.trim()
                &&
                <span className='text-left text-[#6D6E71] block lg:pl-4 text-lg lg:mb-4 mb-2'>
                  Search Results for <b className='text-black font-semibold'>{search}</b>
                </span>
              }
            </div>

            {
              mode === "name"
              &&
              <>
                {loading ? (
              <div className="w-full flex justify-center items-center">
                <ScaleLoader color="#852065" />
              </div>
            ) : 
                  doctors?.slice(((page - 1) * 6), (page * 6))?.length > 0
                    ?
                    <div className="lg:p-4 grid grid-cols-1 sm:grid-cols-2 gap-4 mb-1">
                      {
                        doctors?.slice(((page - 1) * 6), (page * 6)).map((item, index) => (
                          <DocPrimeCard key={index} data={item} />
                        ))
                      }
                    </div>
                    :
                    <div className='w-full h-[300px] flex items-center justify-center'>
                      <p className='w-full' >No Data Available</p>
                    </div>
                }

                <Pagination page={page} setPage={setPage} entries={6} total={doctors?.length} />
              </>
            }

            {
              mode === "department"
              &&
              <>
                {
                  docGrids?.slice(((page - 1) * 2), (page * 2)).length > 0
                    ?
                    docGrids?.slice(((page - 1) * 2), (page * 2)).map((item, index) => (
                      <DeptWiseDocsGrid key={index} data={item} />
                    ))
                    :
                    <div className='w-full h-[300px] flex items-center justify-center'>
                      <p className='w-full' >No Data Available</p>
                    </div>
                }

                <Pagination page={page} setPage={setPage} entries={2} total={docGrids?.length} />
              </>
            }

          </div>


        </section>
        {isModalOpen && (
          <Modal>
            <ModalBox isOpen={isModalOpen} onClose={handleCloseModal} setDeptId={setDeptId} />
          </Modal>
        )}
      </div>
    </div>
  );
}

export default FindDoctor;


// import React, { useState } from 'react'
// import SelectSpecialities from '../../components/doctor/SelectSpecialities'
// import Switch from "react-switch";
// import SearchDoctorsAndSpecialities from '../../components/doctor/SearchDoctorsAndSpecialities'
// import DoctorCard from '../../components/doctor/DoctorCard';
// import SpecializedGrid from '../../components/doctor/SpecializedGrid';
// import { FaArrowUpLong } from "react-icons/fa6";
// import SearchBySymptom from '../../components/doctor/SearchBySymptom';
// import { alphabets } from '../../data/alphabets'

// function FindADoctor() {

//     const [checked, setChecked] = useState(false)
//     return (
//         <div className='max-w-common mx-auto'>
//             {/* header */}
//             <div className='flex justify-between mt-[12rem] py-5'>
//                 <div className='flex flex-col items-start'>
//                     <p>
//                         Medical Departments & Centers
//                     </p>
//                     <h1 className='text-mainHeading'>
//                         Find a Doctor
//                     </h1>
//                 </div>
//                 <div className='flex flex-col items-end'>
//                     <p >
//                         Dont know which doctor to consult ?
//                     </p>
//                     <SearchBySymptom />

//                 </div>
//             </div>

//             <div className='flex mt-10 justify-end'>
//                 <div className='w-4/12 flex flex-col items-start pr-20 '>
//                     <p className='text-gray-600 font-light'>
//                         Filters applied
//                     </p>
//                     <p className='font-light text-paragraph mt-3'>
//                         sorting by <span className='font-normal'>Speciality</span>
//                     </p>
//                     <p className='font-light text-paragraph mt-1'>
//                         Name starting with letter <span className='font-normal'>N</span>
//                     </p>
//                     <SelectSpecialities />
//                     <p className='text-gray-600 font-light mt-10 mb-6'>
//                         Filter by name
//                     </p>
//                     <div>
//                         <div className='ml-7 grid grid-cols-5 gap-3'>
//                             {
//                                 alphabets.map((alphabet, index) => {
//                                     return (
//                                         <button key={index} className='border border-gray-600 rounded-full w-[40px] h-[40px]'>
//                                             {alphabet.name}
//                                         </button>
//                                     )
//                                 })
//                             }
//                         </div>
//                     </div>

//                     <div className='mt-10 flex gap-4 text-sm items-center'>
//                         <p>Go to Top</p>
//                         <button className='bg-primary_color w-[30px] h-[30px] flex items-center justify-center text-white rounded-full'>
//                             <FaArrowUpLong size={15} />
//                         </button>
//                     </div>
//                 </div>
//                 <div className='w-8/12 flex flex-col items-start'>
//                     <div className='flex items-center gap-x-6 w-full mb-7'>
//                         <SearchDoctorsAndSpecialities />

//                         <div className='flex items-center gap-x-1'>
//                             <p className='whitespace-nowrap text-gray-500'>Sort by  :</p>
//                             <span>Name</span>
//                             <Switch
//                                 onChange={() => {
//                                     setChecked(!checked)
//                                 }}
//                                 checkedIcon={null}
//                                 uncheckedIcon={null}
//                                 offColor='#852065'
//                                 onColor='#852065'
//                                 checked={checked}
//                             />
//                             <span>Speciality</span>
//                         </div>
//                     </div>
//                     <p>
//                         Search Results for <span className='text-paragraph font-medium'>Cherian</span>
//                     </p>

//                     {
//                         checked ?
//                             <SpecializedGrid />
//                             :
//                             <div className='grid w-full gap-5 grid-cols-3 mt-5'>
//                                 <DoctorCard />
//                                 <DoctorCard />
//                                 <DoctorCard />
//                                 <DoctorCard />
//                                 <DoctorCard />
//                             </div>
//                     }
//                 </div>
//             </div>
//         </div>
//     )

// }

// export default FindADoctor
