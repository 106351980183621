import React, { useEffect, useState } from "react";
import TopBanner from "../components/common/TopBanner";
import image from "../assets/emergency/emergency_bg_banner.jpeg";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ExpandingTilePart from "../components/emergency/ExpandingTilePart";
import { FaLongArrowAltRight } from "react-icons/fa";
import ServiceRelatedBlogCard from "../components/Services/ServiceRelatedBlogCard";
import { Blog } from "../data/Blogs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useBanner from "../hooks/useBanner";
import axios from "../api/axios";
import { blogsRoute } from "../utils/Endpoint";

function Emergency() {
  const [value, setValue] = useState("1");
  const { t } = useTranslation();
  const words = t("newsBlogs.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    try {
      const res = await axios.get(blogsRoute);

      if (res.status === 200) {
        setBlogs(res.data.blogs);
        console.log(res.data.blogs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentBanner = useBanner("emergency");

  return (
    <div>
      <TopBanner
        title={currentBanner?.title ?? "Emergency Service"}
        image={currentBanner?.image?.location ?? image}
      />
      <div className="max-w-common mx-auto max-md:w-11/12 flex max-md:flex-col gap-10 mt-20 max-md:mt-8">
        <div className="w-7/12 max-md:w-full h-full">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  className="text-2xl font-bold"
                  indicatorColor="secondary"
                  aria-label="overView and learnmore tab"
                >
                  2
                  <Tab
                    className="w-full max-md:w-1/2"
                    style={{
                      fontWeight: "500",
                      fontSize: 19,
                      textTransform: "capitalize",
                    }}
                    label="Overview"
                    value="1"
                  />
                  <Tab
                    className="w-full  max-md:w-1/2"
                    style={{
                      fontWeight: "500",
                      fontSize: 19,
                      textTransform: "capitalize",
                    }}
                    label="Learn more"
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel
                className="text-left "
                style={{ padding: "15px 6px" }}
                value="1"
              >
                All around the world, acutely ill and injured people seek care
                every day.  Frontline providers manage children and adults with
                medical, surgical and obstetric emergencies, including injuries
                and infections, heart attacks and strokes, asthma and acute
                complications of pregnancy.  Prioritising an integrated approach
                to early recognition and resuscitation reduces the impact of all
                of these conditions.
              </TabPanel>
              <TabPanel className="text-left" value="2">
                {/* WHO's Emergency, Trauma and Acute Care programme is dedicated to
                strengthening the emergency care systems that serve as the first
                point of contact with the health system for so much of the
                world, and to supporting the development of quality, timely
                emergency care accessible to all. */}

                <p>Contact : +91 471 3100 100</p>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        <div className="w-5/12 max-md:w-full mt-10 max-md:mt-0">
          <ExpandingTilePart />
        </div>
      </div>

      <div className="my-20 max-w-common max-md:w-11/12 mx-auto ">
        {/* top part */}
        <div className="flex justify-between text-xl font-semibold ">
          <h1 className="text-3xl font-bold text-center capitalize max-md:text-2xl">
            {firstPart}
            <span className="text-primary_color"> {secondPart}</span>
          </h1>
          <div>
            <Link to={"/news"}>
              <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_color hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
                {t("seeMore")}
              </h4>
            </Link>
          </div>
        </div>

        {/* blogs listing */}
        <div className="mt-8 flex gap-5 overflow-hidden overflow-x-scroll">
          {blogs?.map((items) => (
            <div key={items?.id} className="w-fit">
              <ServiceRelatedBlogCard
                data={items}
                title={items?.title}
                date={items?.createdAt}
                catrgory={items?.kind}
                image={items?.image?.location}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Emergency;
