import axios from '../../api/axios';
import { deptFromSymptomRoute} from '../../utils/Endpoint';
import React, {  useState } from 'react'
import { IoClose } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import {  ClockLoader} from 'react-spinners';

const ModalBox = ({ isOpen, onClose, setDeptId }) => {
  // if (!isOpen) return null;
  const [keyword, setKeyword] = useState("")
  // const [department, setDepartment] = useState(null)

  const [loading, setLoading] = useState(false)

  const [symptoms, setSymptoms] = useState([]);

  const addToSymptomsList = () => {
    if (!keyword?.trim()) {
      return
    }
    setSymptoms((prev) => [keyword, ...prev])
    setKeyword("")
  }

  const removeFromSymptoms = (index) => {
    const newSymps = symptoms.filter((_, i) => i !== index)
    setSymptoms(newSymps)
  }

  // This function uses genAI
  const getDepartment = async () => {
    try {
      if (symptoms?.length === 0) {
        return
      }

      setLoading(true)
      const res = await axios.post(deptFromSymptomRoute, { symptoms })

      if (res.status === 200) {
        const result = res.data.result;

        if (result?.dept_id?.trim()) {
          setDeptId(result?.dept_id)
        }
        else {
          setDeptId("")
        }

        // setDepartment(result)
        onClose();
      }
    } catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false)
    }
  }


  return (
    <div>
      <div className=" h-[200px] w-[300px]   md:w-[600px] pt-3 px-2  lg:px-4 ">
        <div>
          <h3 className='text-primary_color text-left text-lg mb-2'>
            Search by Symptom
          </h3>
          <h1 className='text-left text-2xl mb-6'>Tell us your symptoms and and we will help to<br /> find the Specialty</h1>
          <IoClose
            onClick={onClose}
            size={50}
            className="absolute top-2 right-2 text-[#475467] cursor-pointer p-3"
          />

          <form className="flex float-start ">
            <label htmlFor="default-search"
              className=" text-sm  font-medium text-gray-900 sr-only ">
              Search
            </label>
            <div className="relative flex items-center">
              <input
                type="text"
                id="default-search"
                className=" lg:w-96 w-80 mb-4  p-4 text-gray-900 border border-gray text-xl
            rounded-full placeholder-gray-400  "
                placeholder="Search for Symptoms"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <div className="absolute inset-y-0 end-0 pb-4  flex items-center pr-3">
                <div
                  onClick={addToSymptomsList}
                  className="w-10 h-10 flex items-center justify-center rounded-full bg-primary_color">
                  <IoMdAdd color='white' />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='p-2 '>
        <div className='bg-white_faded border flex flex-wrap flex-col gap-4  border-gray rounded-lg h-40 pt-8 mt-2 '>
          {
            symptoms?.map((item, index) => (
              <div key={index} className='w-fit bg-prev_gray flex items-center justify-around gap-2 
              border rounded-xl p-2 h-10 ml-4 lg:ml-6 text-primary_color'>
                <span className=''>
                  {item}
                </span>

                <IoCloseCircleOutline
                  size={24} color='red'
                  onClick={() => removeFromSymptoms(index)}
                />
              </div>
            ))
          }


        </div>
        <button
          type="button"
          disabled={loading}
          onClick={getDepartment}
          className="mt-4 p-2 lg:w-[150px] w-full  pl-4 pr-4 h-10  bg-primary_color text-white 
          rounded-xl flex justify-center items-center ">

          {loading
            ?
            < ClockLoader color='white' size={22} />
            : 'Get Result'}

        </button>


        {/* <br />
        <hr className='text-gray bold border bg-gray_faded shadow-xl h-[3px] ' />
        <div className='flex flex-col'>

          <span className='mb-2 mt-4 text-2xl'>Based on the symptoms you should visit our </span>
          {
            department?._id
              ?
              <div onClick={onClose} className='underline text-lg text-primary_color mb-4 cursor-pointer ' >
                {department?.dept_name} Department
              </div>
              :
              <div onClick={onClose} className='underline text-lg text-primary_color mb-4 cursor-pointer ' >
                General Medicine 
              </div>
          }

        </div> */}


      </div>
    </div>

  );
};
export default ModalBox