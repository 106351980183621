import React from "react";
import TopBanner from "../components/common/TopBanner";
import banner from "../assets/news/bg.jpg";
import useBanner from "../hooks/useBanner";
import BlogListing from "../components/newsAndEvents/BlogListing";
import { useTranslation } from "react-i18next";

const News = () => {
  const currentBanner = useBanner('news');
  const { t } = useTranslation();
  const words = t("newsBlogs.title")?.split(" ");
  const firstPart = words.slice(0, 4).join(" ");
  const secondPart = words.slice(4, words.length - 0).join(" ");

  return (
    <div>
      <TopBanner title={currentBanner?.title ?? "News & Events"}
        image={currentBanner?.image?.location ?? banner}
      />

      {/* heading part */}
      <div className="max-w-common mx-auto flex max-md:flex-col items-center justify-between py-5 lg:py-10 lg:pt-20">
        <div>
          <h1 className="text-3xl font-bold text-center capitalize max-md:text-2xl">
            {firstPart}
            <span className="text-primary_color"> {secondPart}</span>
          </h1>
          <h6 className=" mt-1 text-lg font-light text-left max-md:text-center">
            {t("newsBlogs.subTitle")}
          </h6>
        </div>
      </div>

      {/* Blog part  */}
      <div className="w-full h-full  lg:pb-20">
        <BlogListing />
      </div>
    </div>
  );
};

export default News;
