import React from 'react'
import FindDoctorCard from './FindDoctorCard';
import DocPrimeCard from './DocPrimeCard';

const DeptWiseDocsGrid = ({data}) => {

  return (
    <div className='pt-1 '>
        <div className=''>
          <span className="text-gray flex items-center">
            <hr className="text-gray bold border lg:ml-4 bg-gray_faded shadow-xl w-full h-[3px]" />
            <span className="w-fit text-right p-3">{data?.department_name}</span>
          </span>
        </div>
        <div className="lg:p-4 grid grid-cols-1 sm:grid-cols-2 gap-4 mb-1">
          {data?.list?.map((item, index) => (
            <DocPrimeCard key={index} data={item} />

          ))}
        </div>
      </div>
  )
}

export default DeptWiseDocsGrid