export const baseUrl = process.env.REACT_APP_BASE_URL;

export const dateWiseDocVisit = `/doctor-consultation-available-in-date` // ?department_id=&doctor_id=

export const getTokensRoute = '/get-tokens-against-visit-detail'

export const departmentsRoute = '/api/departments'
export const doctorsRoute = '/api/doctors'

export const reactAppURL = 'https://sp-medofort-next-js.vercel.app';
// export const reactAppURL = 'http://localhost:3000';

export const sendOtpRoute = '/booking-otp';

export const verifyOtpRoute = '/verify-otp';

export const confirmBookingRoute = '/confirm-booking';

export const paymentDetailsRoute = '/pay-now-confirm';

export const payNowRoute = '/booking-payment-success';

export const payLaterRoute = '/booking-pay-later';

export const fetchLabReport = '/get-lab-report-against-bill-credentials';

export const patientDetailRoute = '/patient-basic-details';

export const genderListRoute = '/load-gender-master'

export const redirectToGatewayRoute = '/redirect-to-payu';

export const fetchRadiologyReport = '/get-radiology-report-against-bill-credentials';

export const inquiryRoute = '/api/inquiry'

export const testimonialRoute = '/api/testimonial'

export const checkupsRoute = '/api/checkups'

export const galleryRoute = '/api/gallery'

export const resourceRoute = '/api/resources'

export const newsRoute = '/api/news';

export const eventsRoute = '/api/event';

export const blogsRoute = '/api/blog';

export const articlesRoute = '/api/article';

export const videosRoute = '/api/video';

export const researchRoute = '/api/research';

export const honorRoute = '/api/honor';

export const brochureRoute = '/api/brochure';

export const symptomSuggestRoute = '/api/similar-symptoms';

export const deptFromSymptomRoute = '/api/dept-from-symptoms';

export const PAYU_BASE_URL = "test"

export const bannerRoute = '/api/banner';

export const healthVideosRoute = '/api/health-video';
