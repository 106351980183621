import React, { useEffect, useState } from "react";
import { department } from "./departmentData";
import DepartmentCard from "./DepartmentCard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
import { departmentsRoute } from "../../../utils/Endpoint";

const Department = () => {
  const { t } = useTranslation();
  const words = t("department.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

   const [departments, setDepartments] = useState([]);
  
    const getDepts = async () => {
      try {
        const res = await axios.get(departmentsRoute)
        if (res.status === 200) {
          setDepartments(res.data.result)
          console.log(departments)
        }
      } catch (error) {
        console.log(error)
      }
    }
  
    useEffect(() => {
      getDepts()
    }, [])

  return (
    <div className="max-w-common mx-auto p-3 py-10 md:pt-20">
      {/* Heading part */}
      <div className="flex max-md:flex-col items-center justify-between">
        <div className="flex flex-col items-start max-md:items-center max-md:mb-6">
          <h1 className="font-bold text-2xl md:text-headings font-sans">
            {firstPart}
            <span className="text-primary_color"> {secondPart}</span>
          </h1>
          <h6 className="mt-2 md:text-xl font-newFont font-thin">
            {t("department.subTitle")}
          </h6>
        </div>
        <div>
          <Link to={"/departments"}>
            <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_color hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
              {t("seeMore")}
            </h4>
          </Link>
        </div>
      </div>

      {/* <div className="w-full mt-10 flex flex-wrap max-md:grid max-md:grid-cols-2 items-center gap-5 max-md:gap-3 ">
        {departments?.slice(0,4)?.map((items) => (
          <Link key={items?._id} to={`/departments/detailed-view/${items?._id}`}>
            <DepartmentCard  data={items} />
          </Link>
        ))}
      </div> */}

<div className="w-full mt-10 flex flex-wrap max-md:grid max-md:grid-cols-2 items-center gap-5 max-md:gap-3 ">
  {departments
    ?.filter((dept) => 
      ["ORTHOPEDICS", "CARDIOLOGY", "NEUROLOGY", "MEDICAL ONCOLOGY"].includes(dept?.dept_name)
    )
    ?.map((items) => (
      <Link key={items?._id} to={`/departments/detailed-view/${items?._id}`}>
        <DepartmentCard data={items} />
      </Link>
    ))}
</div>



    </div>
  );
};

export default Department;
