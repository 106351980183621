import { Box, TextField } from '@mui/material'
import React, { useState } from 'react'
import { OTP } from './OTP'
import axios from '../../api/axios';
import { sendOtpRoute, verifyOtpRoute } from '../../utils/Endpoint';
import { toast } from 'react-toastify';

const OtpPage = ({ detail, setDetail, changeHandler, setVerified, isSent, setIsSent }) => {

    const [otp, setOtp] = useState('')

    const sendOtp = () => {
        const mobileNumberPattern = /^[6-9]\d{9}$/;

        if (!mobileNumberPattern.test(detail?.mobile_number)) {
            return toast.warn('Enter a valid number')
        }

        axios.post(sendOtpRoute,
            {
                data: {
                    visit_id: detail?.visit_id,
                    mobile_number: detail?.mobile_number
                }
            }
        )
            .then((res) => {
                if (res?.data?.status !== "error") {
                    setIsSent(true)
                    toast.success('OTP sent')
                } else {
                    throw new Error(res?.data?.msg)
                }
            })
            .catch((error) => {
                toast.error('Failed to sent OTP')
            })

    }

    const VerifyOtp = () => {

        axios.post(verifyOtpRoute,
            {
                data: {
                    visit_id: detail?.visit_id,
                    mobile_number: detail?.mobile_number,
                    otp: otp,
                }
            }
        )
            .then((res) => {
                if (res?.data?.status !== "error") {
                    setVerified(true)
                    setDetail((prev) => ({ ...prev, otp: otp }))

                    toast.success('OTP Verified')
                } else {
                    throw new Error(res?.data?.msg)
                }
            })
            .catch((error) => {
                toast.error('Failed to verify OTP')
            })

    }

    return (
        <div className='w-full flex flex-col gap-5 '>
            {
                isSent
                    ?
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 3,
                        }}
                    >
                        <h2 className='text-primary_color text-xl'>Verification Code</h2>
                        <p>Please enter the code sent to your mobile</p>

                        <OTP separator={<span>-</span>} value={otp} onChange={setOtp} length={4} />
                        <p onClick={sendOtp} className='text-blue-400 cursor-pointer'>Resend Code</p>

                        <button
                            onClick={VerifyOtp}
                            className='w-full sm:w-1/2 bg-primary_color text-white py-2 rounded-lg'>VERIFY</button>
                    </Box>

                    :
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 3,
                        }}
                    >
                        <h2 className='text-primary_color text-xl'>OTP Verification</h2>

                        <TextField fullWidth onChange={changeHandler} value={detail?.mobile_number} size='small' id="outlined-search" label="Enter your mobile number" type="text" name='mobile_number' />

                        <button
                            onClick={sendOtp}
                            className='w-full sm:w-1/2 bg-primary_color text-white py-2 rounded-lg'>Get Code</button>
                    </Box>


            }


        </div>
    )
}

export default OtpPage