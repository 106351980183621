import React from "react";

const VideoCard = ({ video }) => {
  return (
    <div
      className="rounded-lg overflow-hidden shadow-lg bg-white border border-blue-200"
      style={{ width: "full" }}
    >
      <div className="relative h-52 w-full">
        {video?.ytlink?.trim() ? (
          <iframe
            className="w-full  h-full object-cover"
            src={video.ytlink}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={video.name}
          ></iframe>
        ) : (
          <video className="w-full h-full object-cover" controls>
            <source src={video?.attachment?.location} type="video/mp4" />
          </video>
        )}
      </div>
      <div className="flex justify-between items-center px-4 py-2">
        <h3 className="font-medium text-md text-left ">
          {video?.title?.length > 20
            ? `${video.title.slice(0, 20)}...`
            : video?.title}
        </h3>
        <p className="text-sm text-gray-500 text-left">
          {new Date(video?.date)?.toLocaleDateString("en-IN")}
        </p>
      </div>
    </div>
  );
};

export default VideoCard;
