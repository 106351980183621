import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, toaster } from "react-toastify";
import axios from "../../api/axios";
import { inquiryRoute } from "../../utils/Endpoint";
import ReCAPTCHA from "react-google-recaptcha";

const Form = () => {
  const { t } = useTranslation();

  const initialData = {
    type: "insurance",
    name: "",
    email: "",
    phone: "",
    message: "",
  }

  const [formData, setFormData] = useState(initialData);

  const [loading, setLoading] = useState(false);

  // const [successMsg, setSuccessMsg] = useState(null);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const RecaptchaKey = process.env.REACT_APP_G_SITE_KEY;

  // re-captcha
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);
    
    const nameRegex = /^[a-zA-Z\s'-]{2,50}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/;

    if (!nameRegex.test(formData?.name)) {
      return toast.info("Enter a valid name");
    }

    if (!emailRegex.test(formData?.email)) {
      return toast.info("Enter a valid email");
    }

    if (!mobileNumberRegex.test(formData?.phone)) {
     return toast.info("Enter a valid Phone number");
    }

    const envType = process.env.REACT_APP_ENV;

    if (envType === 'production' && !recaptchaToken) {
      return toast.error("Please complete the reCAPTCHA");
    }

    setLoading(true);

    try {
      const res = await axios.post(inquiryRoute, formData)

      if (res.status === 201) {
        console.log("Email sent successfully!");

        setFormData(initialData);

        toast.success("Message Sent Successfully");
        setLoading(false);

      }

    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Please try after some time");
      setErrors((prev) => ({ ...prev, server: "Something went wrong" }));
      setLoading(false);
    }
  };

  return (
    <div className="p-3">
      <div className="max-w-common mx-auto p-5 border border-primary_color rounded-xl flex flex-col w-full md:w-1/2 gap-3 items-center text-primary_color">
        <h1 className="text-primary_color font-bold text-2xl md:text-headings font-sans ">
          {t("contact")}
        </h1>

        <form
          action=""
          onSubmit={handleSubmit}
          className="flex flex-col  w-full gap-5 mt-5 "
        >
          <input
            placeholder="Name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
            required
            className="w-full  bg-transparent border-b border-[#c9c9c9] outline-none text-primary_color"
          />
          {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
          <input
            placeholder="Email ID"
            name="email"
            value={formData?.email}
            onChange={handleChange}
            required
            className="w-full  bg-transparent border-b border-[#c9c9c9] outline-none"
          />
          {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
          <input
            placeholder="Phone Number"
            name="phone"
            value={formData?.phone}
            onChange={handleChange}
            required
            className="w-full  bg-transparent border-b border-[#c9c9c9] outline-none"
          />
          {errors.phone && (
            <span style={{ color: "red" }}>{errors.message}</span>
          )}
          <textarea
            name="message"
            id=""
            value={formData?.message}
            onChange={handleChange}
            placeholder="Your message"
            className="w-full  rounded-lg h-20 p-5 bg-transparent  border-2 focus:outline-none"
          ></textarea>
          {errors.message && (
            <span style={{ color: "white" }}>{errors.message}</span>
          )}

          <ReCAPTCHA sitekey={RecaptchaKey} onChange={handleRecaptchaChange} />

          <button 
          disabled={loading}
          className="w-[100px] bg-primary_color text-white p-3 mt-5 rounded-3xl ">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
