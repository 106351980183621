import { BsFileMedicalFill } from "react-icons/bs";
import React from "react";
import TopBanner from "../components/common/TopBanner";
import OpenPositionCard from "../components/JoinUs/OpenPositionCard";
import image from "../assets/JoinUs/op_theatre.png";
import image1 from "../assets/JoinUs/bg1.png";
import image2 from "../assets/contact/2.jpg";
import { Link } from "react-router-dom";
import useBanner from "../hooks/useBanner";

function JoinUs() {
  const currentBanner = useBanner('join-us')

  return (
    <div className="flex flex-col ">
      {/* join us banner */}
      <TopBanner
        image={currentBanner?.image?.location ?? image1}
        title={currentBanner?.title ?? "Join Us"}
        subTitle={currentBanner?.subtitle ?? "Careers at SP Medifort"}
      />
      <div className="max-w-common md:mx-auto flex items-center justify-center w-full p-3  md:mt-20 relative rounded-b-lg">
        {/* culture */}
        <div
          class="max-w-common md:mx-auto w-full h-full absolute top-0 left-0 opacity-50 rounded-b-2xl"
          style={{ backgroundImage: `url(${image2})`, opacity: 0.5 }}
        ></div>
        <div class="max-w-common md:mx-auto w-full h-full absolute top-0 left-0  bg-gradient-to-t from-transparent to-white rounded-b-lg"></div>
        <div class="max-w-common md:mx-auto w-full h-full absolute top-0 left-0  bg-gradient-to-t from-primary_color opacity-30 to-transparent rounded-b-lg"></div>

        <div className=" md:w-2/3 h-full flex max-md:flex-col relative my-10">
          <img
            className="w-[520px] h-[520px] max-md:hidden object-cover rounded-xl"
            src={image2}
            alt="bg"
          />
          <div className="pb-5 md:p-10 md:pt-[85px]">
            <h1 className="text-5xl font-bold max-md:text-4xl text-nowrap text-primary_color text-left max-md:text-center">
              Join Our Team
            </h1>
            <p className="mt-1 text-2xl text-left font-medium max-md:text-[20px] max-md:text-center">
              Welcome to SP Medifort
            </p>
            <div className="w-full flex items-center justify-center md:justify-start ">
              <a target="blank" href="https://spmedifort.com/career/career.php">
                <button className="text-start border-2 border-primary_color text-sm p-3 px-5 mt-5 text-primary_color hover:bg-primary_color hover:text-white hover:ease-in-out duration-300 rounded">
                  Apply Now
                </button>
              </a>
            </div>
          </div>
          <div className="absolute max-md:relative max-md:px-5 px-12 w-[80.5%] max-md:w-full py-9 max-md:py-6 bg-primary_color rounded-2xl  border-b-[7px] border-[#000] md:bottom-10 right-0">
            <p className="text-white  text-left text-paragraph text-sm md:text-lg font-light">
              At SP Medifort, we are dedicated to providing exceptional care and
              making a difference in our community. Our team of professionals is
              passionate about delivering the highest standards of healthcare
              with compassion, innovation, and excellence.
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-common md:mx-auto w-full p-3 flex flex-col  md:mt-10">
        <div className="flex flex-col items-start ">
          <h1 className="font-bold text-2xl py-10 text-primary_color">
            Why Work with Us?
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 ">
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                1. Commitment to Excellence:
              </h1>
              <p className="font-[300] text-[18px]">
                We strive for the highest quality in patient care, continuously
                improving our services through innovation and education.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                2. Collaborative Environment:
              </h1>
              <p className="font-[300] text-[18px]">
                Our multidisciplinary team works together to provide
                comprehensive care. We value each member's contribution and
                encourage open communication and collaboration.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                3. Career Growth and Development:
              </h1>
              <p className="font-[300] text-[18px]">
                We support your professional growth through continuous learning
                opportunities, including workshops, certifications, and advanced
                training programs. Our mentorship programs help you reach your
                full potential.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                4. State-of-the-Art Facilities:
              </h1>
              <p className="font-[300] text-[18px]">
                Our hospital is equipped with the latest medical technology and
                resources, providing an ideal environment for healthcare
                professionals to thrive and deliver outstanding patient care.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                5. Inclusive and Supportive Culture:
              </h1>
              <p className="font-[300] text-[18px]">
                We are committed to fostering a diverse and inclusive workplace
                where every team member is valued and respected. We believe that
                a supportive work environment leads to better patient outcomes
                and job satisfaction.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start mt-10">
          <h1 className="font-bold text-2xl py-5 pb-10 text-primary_color">
            Career Opportunities.
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-5">
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-xl py-2">Nursing:</h1>
              <p className="font-[300] text-[18px]">
                Join our dedicated nursing staff, providing compassionate care
                and making a difference in patients' lives every day.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">Physicians:</h1>
              <p className="font-[300] text-[18px]">
                Work alongside leading medical experts in a supportive
                environment, focused on delivering top-notch healthcare.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                Allied Health Professionals:
              </h1>
              <p className="font-[300] text-[18px]">
                Explore opportunities in fields such as radiology, laboratory
                services, physical therapy, and more. Your expertise helps us
                provide comprehensive care to our patients.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                Administrative and Support Staff:
              </h1>
              <p className="font-[300] text-[18px]">
                From human resources to finance, IT, and facility management,
                our support staff plays a crucial role in the smooth operation
                of our hospital.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                Volunteer Opportunities:
              </h1>
              <p className="font-[300] text-[18px]">
                Give back to the community by volunteering your time and skills.
                Our volunteers are an integral part of our team, helping us
                enhance the patient experience.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">Employee Benefits:</h1>
              <p className="font-[300] text-[18px]">
                We offer a competitive benefits package designed to support your
                well-being and work-life balance, including:
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">Health and Wellness:</h1>
              <p className="font-[300] text-[18px]">
                Comprehensive health insurance plans, wellness programs, and
                access to our fitness center.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">Financial Security:</h1>
              <p className="font-[300] text-[18px]">
                Competitive salaries, retirement plans, and financial planning
                resources.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">Work-Life Balance:</h1>
              <p className="font-[300] text-[18px]">
                Flexible scheduling, generous paid time off, and employee
                assistance programs.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">
                Professional Development:
              </h1>
              <p className="font-[300] text-[18px]">
                Tuition reimbursement, ongoing education, and leadership
                development programs.
              </p>
            </div>
            <div className="flex flex-col items-start text-start border-2 border-primary_color hover:ease-in-out duration-300 cursor-pointer rounded p-3 hover:bg-primary_color hover:text-white">
              <h1 className="font-[500] text-lg py-2">Recognition Programs:</h1>
              <p className="font-[300] text-[18px]">
                Celebrating our employees’ achievements and milestones through
                various recognition initiatives.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-common md:mx-auto w-full p-3 flex flex-col items-start text-start mt-14">
        <h1 className="font-bold text-primary_color text-2xl">How To Apply?</h1>
        <p className="pt-2">
          Ready to make a difference? Explore our current job openings and apply
          online. Simply click on the "Apply Now" button,
        </p>
        <div className="w-full flex items-center">
          <a target="blank" href="https://spmedifort.com/career/career.php">
            <button className="text-start bg-primary_color text-sm p-3 px-5 mt-5 text-white hover:scale-105 ease-in-out duration-300 rounded">
              Apply Now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default JoinUs;
