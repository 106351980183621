import React, { useEffect, useState } from 'react';
import image from '../assets/healthtalk/healthtalkbanner.jpg'
import axios from '../api/axios';
import { articlesRoute, brochureRoute, healthVideosRoute, researchRoute } from '../utils/Endpoint';
import TopBanner from '../components/common/TopBanner';
import useBanner from '../hooks/useBanner';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useGetAllDoctors from '../hooks/useGetAllDoctors';

const HealthTalk = () => {
    const [articles, setArticles] = useState([])
    const [videos, setVideos] = useState([])
    const [researches, setResearches] = useState([])
    const [brochures, setBrochures] = useState([])
    const currentBanner = useBanner('articles');
    const { t } = useTranslation();
    const words = t("newsBlogs.title")?.split(" ");
    const firstPart = words.slice(0, 4).join(" ");
    const secondPart = words.slice(4, words.length - 0).join(" ");
    const {doctors} =useGetAllDoctors()

    const getArticles = async () => {
        try {
            const res = await axios.get(articlesRoute)

            if (res.status === 200) {
                const result = res.data.articles;

                console.log(result)

                // let articles;
                // if (result?.length > 0 && result?.length < 3) {
                //     if (result?.length === 2) {
                //         articles = [...result, result[0]]
                //     }
                //     else if (result?.length === 1) {
                //         articles = [result[0], result[0], result[0]]
                //     }
                // }
                // else {
                //     articles = result
                // }

                setArticles(result)
              
                  
            }

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const fetchArticlesAuthors = () => {
            const updatedArticles = articles.map((item) => {
                const authorDoctor = doctors.find((doctor) => doctor._id === item.author);
                return {
                    ...item,
                    author: authorDoctor ? authorDoctor.doctor_name : item.author,
                };
            });
            setArticles(updatedArticles);
        };
    
        if (articles.length > 0 && doctors.length > 0) {
            fetchArticlesAuthors();
        }
    }, [articles, doctors]);
    
  

    const getVideos = async () => {
        try {
            const res = await axios.get(healthVideosRoute)

            if (res.status === 200) {
                const videos = res.data.result;
                setVideos(videos)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getResearches = async () => {
        try {
            const res = await axios.get(researchRoute)

            if (res.status === 200) {
                const research = res.data.research;
                setResearches(research)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBrochures = async () => {
        try {
            const res = await axios.get(brochureRoute)

            if (res.status === 200) {
                const result = res.data.result;
                setBrochures(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getArticles()
        getVideos()
        getResearches()
        getBrochures()
    }, [])

    function bytesToMB(bytes) {
        if (isNaN(bytes)) {
            return
        }

        return (bytes / (1024 * 1024)).toFixed(2);
    }

    return (
        <>
            <TopBanner title={currentBanner?.title ?? "Articles"}
                image={currentBanner?.image?.location ?? image}
            />
            <div className="w-full flex flex-col max-w-common mx-auto">
                <div className='w-full flex flex-col items-center gap-2 mt-10 p-5'>
                    <h5>
                        Articles, videos, downloads
                    </h5>
                    <h1 className='font-medium text-[50px] '>
                        Health Talk
                    </h1>
                </div>

                {
                    articles?.length > 0
                    &&
                    <div className='w-full h-[30vh] lg:h-[70vh] sm:h-[75vh] p-5 flex flex-col sm:flex-row gap-4 '>
                        <a href={articles[0]?.file?.location}
                            style={{ backgroundImage: `url(${articles[0]?.image?.location})` }}
                            className='w-full h-full bg-no-repeat bg-cover bg-center overflow-hidden rounded-xl relative'>

                            <div className='bg-black/40 absolute w-full h-full rounded-xl'></div>

                            <div className='w-full h-[25vh] absolute bottom-5 flex flex-col items-start justify-end lg:justify-around text-white  pl-5 pr-2'>
                                <span className='w-fit bg-primary_color text-white p-2 rounded-lg text-sm'>
                                    Article
                                </span>

                                <span className='text-[1.2em] md:text-[2em] block text-left'>
                                    {articles[0]?.title}
                                </span>

                                <p className='text-sm'>
                                    {articles[0]?.author}
                                    <div className='w-[10px] lg:h-[10px] mx-[12px] bg-[#727272] rounded-full inline-block'></div>
                                    {new Date(articles[0]?.updatedAt)?.toLocaleDateString('en-IN')}
                                </p>
                            </div>
                        </a>

                        <div className=' w-full flex flex-col gap-4 '>
                            {
                                articles[1] &&
                                <a href={articles[1]?.file?.location}
                                    style={{ backgroundImage: `url(${articles[1]?.image?.location})` }}
                                    className='w-full h-[30vh] sm:h-2/5 bg-no-repeat bg-cover bg-center relative rounded-xl overflow-hidden'>
                                    <div className='bg-black/40 absolute w-full h-full rounded-xl'></div>

                                    <div className='w-full h-[15vh] absolute bottom-3 flex flex-col items-start justify-around text-white pl-5 pr-2'>
                                        <span className='w-fit bg-primary_color text-white p-2 rounded-lg text-xs'>
                                            Article
                                        </span>

                                        <span className='text-[1.2em] md:text-[1.5em] block text-left'>
                                            {articles[1]?.title}
                                        </span>

                                        <p className='text-xs'>
                                            {articles[1]?.author}
                                            <div className='w-[7px] h-[7px] mx-[8px] bg-[#727272] rounded-full inline-block'></div>
                                            {new Date(articles[1]?.updatedAt)?.toLocaleDateString('en-IN')}
                                        </p>
                                    </div>
                                </a>
                            }

                            {
                                articles[2] &&
                                <a href={articles[2]?.file?.location}
                                    style={{ backgroundImage: `url(${articles[2]?.image?.location})` }}
                                    className='w-full h-[30vh] sm:h-3/5 bg-no-repeat bg-cover bg-center relative rounded-xl border border-black overflow-hidden'>
                                    <div className='bg-black/40 absolute w-full h-full rounded-xl'></div>

                                    <div className='w-full h-[15vh] absolute bottom-3 flex flex-col items-start justify-around text-white pl-5 pr-2'>
                                        <span className='w-fit bg-primary_color text-white p-2 rounded-lg text-xs'>
                                            Article
                                        </span>

                                        <span className='text-[1.2em] md:text-[1.5em] block text-left'>
                                            {articles[2]?.title}
                                        </span>

                                        <p className='text-xs'>
                                            {articles[2]?.author}
                                            <div className='w-[7px] h-[7px] mx-[8px] bg-[#727272] rounded-full inline-block'></div>
                                            {new Date(articles[2]?.updatedAt)?.toLocaleDateString('en-IN')}
                                        </p>
                                    </div>
                                </a>
                            }
                        </div>
                    </div>
                }

                {/* Videos */}
                <div className='w-full h-auto flex flex-col p-3 lg:p-5 mt-5 lg:mt-10'>
                    <div className='w-full h-[15vh] flex flex-col sm:flex-row justify-between'>
                        <div className='w-fit h-full flex flex-col items-start'>
                            <h5>Informative videos</h5>
                            <h3 className='font-medium text-[20px] sm:text-[30px]'>Videos</h3>
                        </div>
                        <Link to={'/videos'} className='w-full sm:w-fit h-fit p-2 rounded-lg border border-primary_color'>See all videos</Link>
                    </div>

                    <div className='w-full h-fit grid lg:grid-cols-2 grid-cols-1 gap-8 mt-4 sm:mt-0 justify-start'>

                        {
                            videos?.map((item, index) => (
                                <iframe
                                    key={index}
                                    className="w-full  h-[360px] object-cover rounded-lg shadow-md"
                                    src={item?.ytlink}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={item?.title}
                                ></iframe>
                            ))
                        }


                    </div>
                </div>

                {/* Latest Articles */}
                <div className='w-full h-auto flex flex-col p-3 lg:p-5 mt-5 lg:mt-10'>
                    <div className='w-full h-[15vh] flex flex-col sm:flex-row justify-between'>
                        <div className='w-fit h-full flex flex-col items-start'>
                            <h5>Informative Articles</h5>
                            <h3 className='font-medium text-[20px] sm:text-[30px]'>Latest Articles</h3>
                        </div>
                    </div>

                    <div className='w-full flex flex-col sm:flex-row flex-wrap justify-start gap-8 mt-4 sm:mt-0'>
                        {
                            articles.map((item, i) => (

                                <a key={i}
                                    href={item?.file?.location}
                                    className='sm:w-[20vw] flex flex-col shadow-xl p-2 rounded-xl'>
                                    <img
                                        src={item?.image?.location}
                                        className='h-[30vh] rounded-t-xl object-cover'
                                    />
                                    <div className='flex flex-col items-start h-fit p-2'>
                                        <span className='text-primary_color font-medium'>Article</span>
                                        <span className='text-left'>{item?.title}</span>
                                        <p className='text-xs text-[#929292]'>
                                            {item?.author}
                                            <span class="inline-block w-[1px] h-[10px] mx-[5px] bg-black"></span>
                                            {new Date(item?.updatedAt)?.toLocaleDateString('en-IN')}
                                        </p>
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                </div>

                {/* Latest Researches */}
                <div className='w-full h-auto flex flex-col p-3 lg:p-5 mt-5 lg:mt-10'>
                    <div className='w-full h-[15vh] flex flex-col sm:flex-row justify-between'>
                        <div className='w-fit h-full flex flex-col items-start'>
                            <h5>Informative Researches</h5>
                            <h3 className='font-medium text-[20px] sm:text-[30px]'>Latest Researches</h3>
                        </div>
                        <button className='w-full sm:w-fit h-fit p-2 rounded-lg border border-primary_color'>See all researches</button>
                    </div>

                    <div className='w-full flex flex-col sm:flex-row flex-wrap justify-start gap-8 mt-4 sm:mt-0'>
                        {
                            researches.map((item, i) => (

                                <a key={i}
                                    href={item?.file?.location}
                                    className='sm:w-[20vw] flex flex-col rounded-t-xl'>
                                    <img
                                        src={item?.image?.location}
                                        className='h-[30vh] rounded-t-xl '
                                    />
                                    <div className='flex flex-col items-start h-fit py-2'>
                                        <span className='text-primary_color font-medium'>Research</span>
                                        <span className='text-left'>{item?.title}</span>
                                        <p className='text-xs text-[#929292]'>
                                            {item?.authors.join(', ')}
                                            <span class="inline-block w-[1px] h-[10px] mx-[5px] bg-black"></span>
                                            {new Date(item?.publishedDate)?.toLocaleDateString('en-IN')}
                                        </p>
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                </div>

                <hr className=' mx-5 h-[2px] bg-primary_color' />

                {/* Brochures */}

                <div className="w-full mt-5 flex flex-col items-center">
                    <div className='w-full flex flex-col items-center gap-[5px] p-5'>
                        <h5 className='text-[10px] sm:text-[15px]'>
                            Informative Brochures
                        </h5>
                        <h1 className='font-medium text-[20px] sm:text-[30px] '>
                            Downloads
                        </h1>
                    </div>

                    <div className='w-full sm:w-[70%] flex flex-col sm:flex-row flex-wrap justify-start gap-8 items-start p-5 sm:p-0'>

                        {/* brochure */}
                        {
                            brochures.map((item, i) => (

                                <a key={i}
                                    href={item?.attachment?.location}
                                    className='w-full sm:w-fit rounded-lg p-2 flex gap-4 border border-[#555]'>
                                    <img
                                        src={require('../assets/healthtalk/brochure.png')}
                                        className='w-[70px] h-[70px]'
                                    />

                                    <div className='flex flex-col items-start pr-4'>
                                        <span className='text-lg font-medium capitalize'>{item?.title}</span>
                                        <span className='text-sm'>{bytesToMB(item?.attachment?.size)} MB</span>
                                        <span className='text-[#606060] text-sm mt-[5px]'>Click to download</span>
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default HealthTalk