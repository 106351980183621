import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import JoinUs from './pages/JoinUs';
import Diagnostics from './pages/Diagnostics';
import ContactUs from './pages/ContactUs';
import AboutUsPage from './pages/AboutUsPage';
import Specialties from './pages/Specialities';
import DepartmentsPage from './pages/Departments';
import OurTeam from './pages/OurTeam';
import Hospitals from './pages/Hospitals';
import Services from './pages/Services';
import Signup from './pages/signup/Signup';
import Login from './pages/signup/Login';
import Profile from './pages/patient/Profile';
import Report from './pages/patient/Report';
import Corporate from './pages/Corporate';
import Preventive from './pages/Preventive';
import Emergency from './pages/Emergency';
import Insurance from './pages/Insurance';
import ScrollToTop from './components/ScrollToTop';
import FindADoctor from './pages/doctor/FindADoctor';
import DoctorProfile from './pages/doctor/DoctorProfile';
import HealthTalk from './pages/HealthTalk';
import HomeCare from './pages/HomeCare';
import CoreManagement from './pages/CoreManagement';
import DepartmentDetailedView from './pages/DepartmentDetailedView';
import News from './pages/News';
import NewsDetailedView from './pages/NewsDetaildView';
import NotFound from './pages/NotFound';
import Gallery from './pages/gallery/Gallery';
import Videos from './pages/videos/page';
import Reports from './pages/reports/page';
import Articles from './pages/Articles';

function App() {

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about-us' element={<AboutUsPage />} />
            <Route path='/join-us' element={<JoinUs />} />
            <Route path='/diagnostics' element={<Diagnostics />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/specialties' element={<Specialties />} />
            
            <Route path='/departments' element={<DepartmentsPage />} />
            <Route path='/departments/detailed-view/:id' element={<DepartmentDetailedView />} />

            {/* <Route path='/our-team' element={<OurTeam />} /> */}
            <Route path='/our-team' element={<FindADoctor />} />

            <Route path='/news' element={<News />} />
            <Route path='/news/:id' element={<NewsDetailedView />} />
            <Route path='/articles' element={<HealthTalk />} />

            <Route path='/hospitals' element={<Hospitals />} />
            <Route path='/services' element={<Services />} />

            {/* <Route path='/sign-up' element={<Signup />} /> */}
            {/* <Route path='/login' element={<Login />} /> */}
            {/* <Route path='/profile' element={<Profile />} /> */}
            {/* <Route path='/report' element={<Report />} /> */}
            
            <Route path='/reports' element={<Reports />} />
            <Route path='/corporate' element={<Corporate />} />

            <Route path='/preventive' element={<Preventive />} />
            <Route path='/preventive?testData=test' element={<Preventive />} />
            
            <Route path='/emergency' element={<Emergency />} />
            <Route path='/insurance' element={<Insurance />} />

            <Route path='/find-a-doctor' element={<FindADoctor />} />
            <Route path='/doctor-profile/:id' element={<DoctorProfile />} />

            <Route path='/health-talk' element={<HealthTalk/>} />
            <Route path='/home-care' element={<HomeCare/>}/>

            <Route path='/core-management' element={<CoreManagement/>} />

            <Route path='/images' element={<Gallery/>} />
            <Route path='/videos' element={<Videos/>} />

            <Route path='*' element={<NotFound/>} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
