import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import { blogsRoute } from "../utils/Endpoint";
import parse from 'html-react-parser';

const NewsDetailedView = () => {
  const [data, setData] = useState();
  const { id } = useParams();

  const [content, setContent] = useState("");
  const [date, setDate] = useState(null);

  const getBlog = async()=>{
    try {
      const res = await axios.get(`${blogsRoute}/${id}`)

      if(res.status === 200){
        setData(res.data.blog)
        const blog =  res.data.blog
        const content = blog.content ?? "";
        const parsedContent = parse(content)
        setContent(parsedContent)

        const date = blog.createdAt ? new Date(blog.createdAt).toLocaleDateString('en-IN') : 'NIL'
        setDate(date)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlog()
  }, [id]);

  return (
    <div className="py-28 md:py-36 max-w-[1280px] mx-auto p-3 ">
      <div className="mt-14 lg:mt-10 pb-5 md:pb-10">
        <h1 className="text-start font-bold text-2xl md:text-5xl text-primary_color capitalize">
          {data?.title}
        </h1>

        <div className="text-start pt-2">
          <span className="font-medium ">Post Date :</span> {date }
        </div>

      </div>
      <div className="relative w-full h-[55vh] md:h-[300px] overflow-hidden rounded-xl">
        <img
          src={data?.image?.location}
          alt="banner"
          className="w-full h-full absolute top-0 left-0 object-cover rounded-lg blur-[5px]"
        />
        <img
          src={data?.image?.location}
          alt="banner"
          className="w-full absolute object-contain h-full md:h-[300px] rounded-xl  z-10 p-5 "
        />
      </div>


      <div className="mt-10 min-h-[100px] text-start prose prose-base prose-h1:font-semibold prose-custom prose-h2:font-medium prose-p:font-medium prose-ul:font-medium max-w-none"
       style={{
        lineHeight: "1.6",
      }}
      >
      {content || "Content not available"}
          
      </div>
    </div>
  );
};

export default NewsDetailedView;
