import React from 'react'
import { Link } from 'react-router-dom'

const BlogCardFullVew = (props) => {
    return (
        <div className="overflow-hidden h-[360px] shadow-[6px_6px_70px_0px_rgba(0,0,0,0.2)] rounded-t-2xl">
            <Link to={`/news/${props?.data?._id}`} className="w-[430px] h-[230px] overflow-hidden">
                <div className="w-full h-[210px] overflow-hidden rounded-t-xl flex items-center justify-center p-2">
                    <img
                        src={props?.data?.image?.location}
                        alt="banner"
                        className="w-full h-full object-cover rounded-xl cursor-pointer hover:scale-105 ease-in-out duration-200 -z-10"
                    />
                </div>
            </Link>
            <div className="px-5 pt-3">
                <div>
                    <h2 className="text-wrap text-xl lg:leading-6 font-semibold text-primary_color text-left line-clamp-2">
                        {props?.data?.title}
                    </h2>
                    <p className="text-[16px] max-md:text-base font-light text-left mt-2">
                        <span className="font-medium text-black/50">Post Date :</span>{" "}
                        {new Date(props?.data?.createdAt).toLocaleDateString('en-IN')}
                    </p>
                    <p className="text-[16px] max-md:text-base font-light text-left ">
                        <span className="font-medium text-black/50">Author :</span>{" "}
                        {props?.data?.author}
                    </p>
                </div>
            </div>
            <div className="flex flex-col -mt-[50%] lg:-mt-[40%] z-50">
                <div className="flex items-center h-[40px] w-fit mr-5">
                    <span className="bg-primary_color h-[40px] flex items-center">
                        <h2 className="  text-white text-base  px-6 line-clamp-1">
                            {props?.data?.kind}
                        </h2>
                    </span>
                    <div
                        style={{
                            width: 0,
                            height: 0,
                            borderTop: "20px solid transparent",
                            borderBottom: "20px solid transparent",
                            borderLeft: `20px solid rgb(133 32 101)`,
                        }}
                    >
                        {" "}
                    </div>
                </div>
                <div
                    style={{
                        width: 0,
                        height: 0,
                        borderTop: "10px solid transparent",
                        borderBottom: "10px solid transparent",
                        borderRight: "8px solid #5B0841",
                    }}
                    className="-z-10 -mt-[10px]"
                >
                    {" "}
                </div>
            </div>
        </div>
    )
}

export default BlogCardFullVew
