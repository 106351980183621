import React from "react";
import bannerImage from "../assets/Insurance/insurance_bg_banner.png";
import TopBanner from "../components/common/TopBanner";
import Corporates from "../components/insurane/Corporates";
import Form from "../components/insurane/Form";
import useBanner from "../hooks/useBanner";
function Insurance() {
  const TieUpNames = [
    "ADITYA BIRLA HEALTH INSURANCE CO. LTD.",
    "ALANI HEALTHCARE",
    "EAST WEST ASSIST PVL LTD.",
    "FAITH INDIA TPA PVT",
    "MEDSAVE HEALTHCARC LTD",
    "GENINS INDIA TPA LTD",
    "VIDAL HEALTHCARE TPA",
    "ICICI PRUDENTIAL LIFE INSURANCE CO. LTD.",
    "FAITH INDIA TPA PVT",
  ];

  const currentBanner = useBanner('insurance')

  return (
    <div>
      <TopBanner image={currentBanner?.image?.location ?? bannerImage}
        title={currentBanner?.title ?? "Corporate & Insurance"} />
      <div className="mt-12 flex flex-col max-w-common mx-auto md:mb-20 p-3">
        <h1 className="text-mainHeading font-medium">
          {" "}
          <span className="text-primary_color">Insurance</span>
        </h1>
        <p className="mt-5">
          At SP Medifort, we welcome all insured patients, ensuring you receive
          the care you need without financial stress. Understanding insurance
          can be complex, but our knowledgeable team is here to help. For
          comprehensive details about your insurance options, please reach out
          to our front desk. We're dedicated to making your experience seamless
          and stress-free. Contact us today to learn
          more about how we can support your health and wellness.
        </p>
      </div>
      <div className="mb-20 max-w-common mx-auto ">
        <Form />
      </div>

      <div className="pb-20 max-w-common mx-auto">

        <h1 className="text-mainHeading font-semibold ">
          {" "}
          <span className="text-primary_color ">Insurance & TPA</span>
        </h1>
        <p className="text-lg font-medium text-gray-500 pb-5 lg:pb-8">
          Now experience cashless access to the best of care
        </p>
        <Corporates />
      </div>
    </div>
  );
}

export default Insurance;
