import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import banner2 from "../../../assets/landing/Banner2.png";
import banner1 from "../../../assets/landing/Banner1.jpg";
import banner3 from "../../../assets/landing/banner3.jpg";
import banner4 from "../../../assets/landing/banner4.jpg";
import banner5 from "../../../assets/landing/Banner5.jpg";
import bannerMob2 from "../../../assets/landing/BannerMob2.png";
import bannerMob1 from "../../../assets/landing/BannerMob1.jpg";
import bannerMob3 from "../../../assets/landing/bannerMob3.jpg";
import bannerMob4 from "../../../assets/landing/BannerMob4.png";
import bannerMob5 from "../../../assets/landing/BannerMob5.jpg";
import axios from "../../../api/axios";
import { bannerRoute } from "../../../utils/Endpoint";

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  // const slides = [
  //   { type: "image", src: banner5 },
  //   { type: "image", src: banner2 },
  //   { type: "image", src: banner1 },
  //   { type: "image", src: banner3 },
  //   { type: "image", src: banner4 },
  // ];

  // const mobSlides = [
  //   { type: "image", src: bannerMob5 },
  //   { type: "image", src: bannerMob2 },
  //   { type: "image", src: bannerMob1 },
  //   { type: "image", src: bannerMob3 },
  //   { type: "image", src: bannerMob4 },
  // ];

  const [slides, setSlides] = useState([])
  const [mobSlides, setmobSlides] = useState([])

  const currentSlides = isMobile ? mobSlides : slides;


  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === currentSlides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? currentSlides.length - 1 : prevSlide - 1
    );
  };

  const getBanners = async()=>{
    try {
      const panel = 'home'
      const res = await axios.get(`${bannerRoute}?panel=${panel}`)

      if(res.status === 200){
        const result = res.data.banners;

        const desktopSlides = result?.filter(item=> item?.screenType === 'desktop') 
        const mobileSlides = result?.filter(item=> item?.screenType === 'mobile')
        console.log(desktopSlides,mobSlides)

        setSlides(desktopSlides)
        setmobSlides(mobileSlides)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{getBanners()},[isMobile])

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className="relative h-[450px] lg:h-screen w-full overflow-hidden">
      <div className="relative w-full h-full">
        <div className="flex transition-transform duration-500 ease-in-out transform"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {currentSlides.map((slide, index) => (
            <div
              key={index}
              className="flex-none w-full h-full mt-32"
              style={{ backgroundImage: `url(${slide?.image?.location})` }}
            >
              
                <img
                  src={slide?.image?.location}
                  alt={`Slide ${index}`}
                  className="object-cover w-full h-full"
                />

              {/* {slide.type === "image" && (
                <img
                  src={slide.src}
                  alt={`Slide ${index}`}
                  className="object-cover w-full h-full"
                />
              )} */}
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="absolute inset-y-1/2 left-0 flex justify-between w-full px-5 -translate-y-1/2">
        <button
          onClick={prevSlide}
          className="text-white text-3xl hover:text-gray-300"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={nextSlide}
          className="text-white text-3xl hover:text-gray-300"
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
