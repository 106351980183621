import React from "react";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";

const BotFoot = () => {
  return (
    <div className="w-full md:h-[10vh] bg-white flex justify-between items-center text-primary_color">
      <div className="flex flex-col md:flex-row justify-between items-center w-full  max-w-common mx-auto py-5 gap-2">
        <div className="flex gap-2">
          <span className=" text-[12px] md:text-[16px]">
            © 2024 SP Hospitals, Inc. All rights reserved.
          </span>
          <span className="cursor-pointer text-[12px] md:text-[16px]">
            Privacy
          </span>
          <span className="cursor-pointer text-[12px] md:text-[16px]">
            Sitemap
          </span>
        </div>

        <div className=" flex gap-2">
          <a 
           href="#"
          //  target="_blank"
          //  rel="noopener noreferrer"
          className="w-10 h-10 border-2 border-primary_color rounded-full flex justify-center items-center cursor-pointer">
            <FaXTwitter />
          </a>
          <a
            href="https://www.youtube.com/channel/UClDlhQhdJfOb9cl3WSxzEkg"
            target="_blank"
            rel="noopener noreferrer"
            className="w-10 h-10 border-2 border-primary_color rounded-full flex justify-center items-center cursor-pointer"
          >
            <FaYoutube />
          </a>
          <a
           href="https://www.facebook.com/spmediforthospital/"
           target="_blank"
           rel="noopener noreferrer"
          className="w-10 h-10 border-2 border-primary_color rounded-full flex justify-center items-center cursor-pointer">
            <FaFacebookF />
          </a>
          <a 
           href="https://www.linkedin.com/company/sp-medifort-hospital/?originalSubdomain=in"
           target="_blank"
           rel="noopener noreferrer"
          className="w-10 h-10 border-2 border-primary_color rounded-full flex justify-center items-center cursor-pointer">
            <FaLinkedin />
          </a>
          <a
           href="https://www.instagram.com/spmedifort/?hl=en"
           target="_blank"
           rel="noopener noreferrer"
          className="w-10 h-10 border-2 border-primary_color rounded-full flex justify-center items-center cursor-pointer">
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BotFoot;
