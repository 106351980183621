"use client";
import { useEffect, useState } from "react";
import "./gallery.css";
import image1 from "../../assets/gallery/image 1.jpg";
import image2 from "../../assets/gallery/image 2.jpg";
import image3 from "../../assets/gallery/image 3.jpg";
import image4 from "../../assets/gallery/image 4.jpg";
import image5 from "../../assets/gallery/image 5.jpg";
import image6 from "../../assets/gallery/image 6.png";
import GalCarousel from "./GalCarousel";
import { galleryRoute } from '../../utils/Endpoint'
import axios from '../../api/axios'

const Gallery = () => {
  const [modal, setModal] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const AllImages = [image1, image2, image3, image4, image5, image6];

  const [dispImgs, setDispImgs] = useState([]);

  const getImages = async () => {
    try {
      const res = await axios.get(galleryRoute)

      if (res.status === 200) {
        setDispImgs(res.data.galleries)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const initModal = (index) => {
    setImgIndex(index);
    setModal(true);
  };

  useEffect(() => {
    getImages()
  }, [])

  return (
    <div className="max-w-common mx-auto mt-16 p-4 flex flex-col items-center ">

      {/* Gallery */}
      <div className="w-full mt-3 sm:mt-6 py-16 figure">
        {dispImgs?.map((item, i) => (
          <img
            key={i}
            src={item?.image?.location}
            className="pic"
            onClick={() => initModal(i)}
            alt="displayImage"
          />
        ))}
      </div>

      {modal && (
        <GalCarousel
          setModal={setModal}
          imgIndex={imgIndex}
          imgArray={dispImgs}
        />
      )}
    </div>
  );
};

export default Gallery;
