import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import bannerImage from "../../../assets/contact/hospital.png";
// import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../../api/axios";
import { inquiryRoute } from "../../../utils/Endpoint";

const ContactUs = ({ id }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [errors, setErrors] = useState({});

  const initialData = {
    type: "contact",
    name: "",
    email: "",
    phone: "",
    message: "",
  }

  const [formData, setFormData] = useState(initialData);

  // Service keys
  // const ServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  // const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  // const PublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const RecaptchaKey = process.env.REACT_APP_G_SITE_KEY;

  // email.js 
  // useEffect(() => {
  //   emailjs.init(PublicKey);
  // }, [PublicKey]);

  // onchange handler
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // re-captcha
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  // submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const envType = process.env.REACT_APP_ENV;

    if (envType === 'production' && !recaptchaToken) {
      return toast.error("Please complete the reCAPTCHA");
    }

    const nameRegex = /^[a-zA-Z\s'-]{2,50}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/;

    if (!nameRegex.test(formData?.name)) {
      return toast.info("Enter a valid name");
    }

    if (!emailRegex.test(formData?.email)) {
      return toast.info("Enter a valid email");
    }

    if (!mobileNumberRegex.test(formData?.phone)) {
      return toast.info("Enter a valid Phone number");
    }

    setLoading(true);

    try {
      const res = await axios.post(inquiryRoute, formData)

      if(res.status === 201){
        console.log("Email sent successfully!");
  
        setFormData(initialData);
  
        toast.success("Message Sent Successfully");
        setLoading(false);

      }

    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Please try after some time");
      setErrors((prev) => ({ ...prev, server: "Something went wrong" }));
      setLoading(false);
    }
  };

  return (
    <div
      id={id}
      style={{
        backgroundImage: `url(${bannerImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="w-full h-auto px-20 max-md:px-6 md:py-10 
      flex flex-col items-start justify-around bg-cover bg-no-repeat bg-center font-newFont"
    >
      <div className="max-w-common mx-auto p-3 py-10 md:pt-20 flex flex-col w-full gap-3 items-start max-md:gap-y-4">
        <h1 className="text-[#c9c9c9] font-bold text-2xl md:text-headings font-sans pb-5">
          {t("contact")}
        </h1>
        <p
          style={{ wordWrap: "break-word" }}
          className="text-[#ffffff] text-left text-xl max-md:text-lg max-w-[25ch] max-md:max-w-full"
        >
          {t("contactSubtitle")}
        </p>
        <form
          action=""
          onSubmit={handleSubmit}
          className="flex flex-col  w-full gap-5 mt-5 "
        >
          <input
            placeholder="Name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
            required
            className="w-full md:w-3/4 bg-transparent border-b border-[#c9c9c9] outline-none text-white"
          />
          {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
          <input
            placeholder="Email ID"
            name="email"
            value={formData?.email}
            onChange={handleChange}
            required
            className="w-full md:w-3/4 bg-transparent border-b border-[#c9c9c9] outline-none text-white"
          />
          {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
          <input
            placeholder="Phone Number"
            name="phone"
            value={formData?.phone}
            onChange={handleChange}
            required
            className="w-full md:w-3/4 bg-transparent border-b border-[#c9c9c9] outline-none text-white"
          />
          {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
          <textarea
            name="message"
            value={formData?.message}
            onChange={handleChange}
            placeholder="Your message"
            className="w-full md:w-3/4 rounded-lg h-20 p-5 bg-transparent text-white border-2"
          ></textarea>
          {errors.message && (
            <span style={{ color: "red" }}>{errors.message}</span>
          )}

          {/* Add the reCAPTCHA component */}
          <ReCAPTCHA sitekey={RecaptchaKey} onChange={handleRecaptchaChange} />

          <button
            className="w-[100px] bg-[#ffffff] p-3 mt-5 rounded-3xl"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
