import React, { useEffect, useState } from 'react'
import '../components/diagnostics/Diagnostics.css'
import TopBanner from '../components/common/TopBanner'
import image from '../assets/diagnostics/lab.png'
import axios from '../api/axios'
import { bannerRoute } from '../utils/Endpoint'
import useBanner from '../hooks/useBanner'
function Diagnostics() {

    const currentBanner = useBanner('diagnostics')

    return (
        <div>
            {/* diagnostics banner */}
            <TopBanner title={currentBanner?.title ?? 'Diagnostics'} image={currentBanner?.image?.location ?? image} />

            {/* our services */}
            <div className='max-w-common mx-auto mt-24 mb-20 flex  max-md:flex-col items-center gap-7'>
                <div className='max-md:w-11/12 text-right max-md:text-center'>
                    <h1 className='text-mainHeading font-medium'>Our <span className='text-primary_color'>Services</span></h1>
                    <p className='text-2xl font-light'>
                        <span className='font-normal'>SP Medifort</span> is dedicated to providing the finest quality and safest care to our patients, backed by empathy and compassion
                    </p>
                </div>
                <div className='w-full max-md:w-11/12 ml-10 max-md:ml-0 flex rounded-2xl bg-primary_color justify-between p-20 max-md:p-7 border-[5px] border-white shadow-[-9px_12px_53px_0px_rgba(0,0,0,0.2)]'>
                    <ul className='inline-block text-left text-lg font-medium text-white service-list'>
                        <li>Fully computerized Lab</li>
                        <li>Digital X-ray</li>
                        <li>Interventional Radioloy</li>
                        <li>4D Ultrasound Scan</li>
                        <ul className='inner-list font-normal text-sm py-2'>
                            <li>Abdomen (Paediatric) + Adult / Abdomen + Pelvis</li>
                            <li>Early Obstetric / Breast / Scrotum Doppler</li>
                            <li>Early Obstetric / Breast / Scrotum Doppler</li>
                        </ul>
                        <li>Follicular Study</li>
                        <li>Foetal Anomaly Scan</li>
                    </ul>
                </div>
            </div>

            {/* Book a test */}
            {/* <div className='flex flex-col gap-10 mt-14'>
                <h1 className='text-5xl font-medium'>Book a <span className='text-primary_color'>Test</span></h1>
                <div className='flex h-[50px]'>
                    <input placeholder='Phone number'  type="text" />
                    <button>Book</button>
                </div>
            </div> */}
        </div>
    )
}

export default Diagnostics
