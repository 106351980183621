import { useEffect, useState } from "react";
import axios from "../../api/axios";
import emailjs from "@emailjs/browser";
import BookAppoinment from "../../components/doctor/BookAppoinment";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  dateWiseDocVisit,
  doctorsRoute,
  getTokensRoute,
} from "../../utils/Endpoint";
import { toast } from "react-toastify";
import logo from "../../assets/logo/Logo.svg";
import bgImage from "../../assets/doctors/bg.png";
import avatar from "../../assets/doctors/avatar.png";
import "../../components/doctor/DoctorProfile.css";
import ApptModal from "../../components/bookAptModal/ApptModal";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const DoctorProfile = () => {
  const [docData, setDocData] = useState({});
  const location = useLocation();
  const id = location?.pathname?.split("/")[2];

  const [open, setOpen] = useState(false);

  const PublicKey = process.env.NEXT_PUBLIC_EMAILJS_PUBLIC_KEY;

  const [doctor_id, setDocId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [blog, setBlog] = useState([]);

  const tommorrowDate = () => {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const getDocData = async (id) => {
    try {
      const response = await axios.get(`${doctorsRoute}/${id}`);
      if (response.status === 200) {
        const docData = response?.data?.result;
        setDocData(docData);
        setDocId(docData?.doctor_id);
        DateWiseVisit(docData?.doctor_id, tommorrowDate());
        setArticles(docData?.articles);
        setBlog(docData?.blogs);
        console.log(docData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id?.trim()) {
      getDocData(id);
    }
  }, [id]);

  const [fDates, setFDates] = useState([]);
  const [bookDate, setBookDate] = useState(tommorrowDate());
  const [visits, setVisits] = useState([]);
  const [visitId, setVisitId] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [tokenId, setTokenId] = useState(null);

  // Appointment Modal
  const [openApptModal, setOpenApptModal] = useState(false);
  const [apptData, setApptData] = useState({});

  const [consultObj, setConsultObj] = useState({});

  const getNextWeekDates = () => {
    const dates = [];
    const today = new Date();

    for (let i = 1; i <= 5; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + i);
      dates.push(nextDay);
    }

    setFDates([...dates]);
  };

  useEffect(() => {
    getNextWeekDates();
  }, []);

  const DateWiseVisit = async (docId, bookDate) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${dateWiseDocVisit}?doctor_id=${docId}`,
        { data: { date: bookDate } }
      );

      const data = response?.data?.data[0] ?? {};
      setConsultObj(data);

      if (data?.visits?.length > 0) {
        setVisits([...data?.visits]);
      } else {
        setVisits([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after the request finishes
    }
  };

  useEffect(() => {
    if (doctor_id) {
      DateWiseVisit(doctor_id, bookDate);
    }
  }, [bookDate]);

  const FetchTokens = async () => {
    try {
      setLoading(true);
      const response = await axios.post(getTokensRoute, {
        data: { visit_id: visitId },
      });

      if (response) {
        const tokens = response?.data?.data;
        setTokens([...tokens]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after the request finishes
    }
  };

  useEffect(() => {
    FetchTokens();
  }, [visitId]);

  useEffect(() => {
    emailjs.init(PublicKey);
  }, []);

  const scrollToBook = () => {
    const section = document.getElementById("booking");

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });

      const yOffset = -150;
      const y = section.getBoundingClientRect().top + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const openBookModal = () => {
    if (tokenId) {
      setOpen(true);
    } else {
      if (!bookDate) {
        return toast.warn("Select a date");
      } else if (!visitId) {
        return toast.warn("Choose a Session");
      } else toast.warn("Select a token");
    }
  };

  const ClearTokenAndSession = () => {
    setTokenId("");
    setVisitId("");
  };

  console.log({ consultObj });

  return (
    <div className="max-w-common mx-auto w-full p-3 md:p-2">
      <div className="mt-36 md:mt-48 relative flex flex-col md:flex-row gap-x-14">
        <img src={logo} alt="" className="absolute right-0 top-0 md:scale-85" />
        <div className="w-full md:w-3/12  flex flex-col items-start ">
          <div
            className="rounded-lg w-full"
            style={{
              backgroundImage: `url(${bgImage.src})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img
              src={docData?.image?.location || avatar}
              alt=""
              className="h-[200px] w-full object-top object-cover  rounded-lg"
            />
          </div>
          <div className="flex flex-col items-start justify-between w-full mt-3">
            <h1 className=" font-medium md:font-semibold text-primary_color text-xl">
              {docData?.doctor_name}

              {docData?.opd_timings?.length > 0 && (
                <p className="opacity-75 text-sm font-normal mt-1  text-black text-start ">
                  OPD timings
                </p>
              )}
            </h1>

            {docData?.opd_timings?.map((item, i) => (
              <div key={i} className="w-full flex items-center justify-between">
                <p className="text-lg text-primary_color font-semibold">
                  {item?.days}
                </p>
                <p className="text-lg ">{item?.time}</p>
              </div>
            ))}
          </div>

          <div className="mb-10 md:mb-0 mt-5 w-full">
            <button
              onClick={scrollToBook}
              className="border-none w-full px-5 py-3 rounded text-sm text-white bg-primary_color font-medium hover:scale-105 ease-in-out duration-300"
            >
              Book an appointment
            </button>
          </div>
          {/* Latest Articles Section */}
          <div className= "flex flex-col items-start mt-10">
            <h2 className="text-2xl font-semibold text-primary_color mb-5">
              Latest Articles
            </h2>
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <ScaleLoader color="#852065" />
              </div>
            ) : articles?.length > 0 ? (
              <div>
                {/* Show first 3 articles */}
                <div className="grid grid-cols-2 gap-2">
                  {articles.slice(0, 3).map((item, index) => (
                    <a
                      key={index}
                      href={item?.file?.location}
                      className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                    >
                      {/* Card Image */}
                      <img
                        src={item?.image?.location}
                        alt="Article"
                        className="w-full h-20 object-cover"
                      />
                      {/* Card Content */}
                      <div className="p-2 flex flex-col flex-grow">
                        {/* Title */}
                        <h3 className="text-sm text-left font-semibold text-gray-800 truncate">
                          {item?.title}
                        </h3>
                        <h3 className="text-[12px] text-left font-semibold text-gray-800 truncate">
                          {item?.content}
                        </h3>
                        {/* Description */}
                        <p className="text-[10px] text-left text-gray-600 mt-1 line-clamp-2">
                          {docData?.doctor_name} -{" "}
                          {new Date(item?.updatedAt).toLocaleDateString(
                            "en-IN"
                          )}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>

                {/* View More Button */}
                {articles?.length >= 3 && (
                  <div className="mt-4 text-center">
                    <a
                      href="/articles"
                      className="text-primary_color hover:text-[#99467F] font-semibold"
                    >
                      View More
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center text-gray-500">
                <p>No articles available at the moment.</p>
              </div>
            )}
          </div>
          {/* Latest Blogs Section */}
          <div className="flex flex-col items-start mt-10">
            <h2 className="text-2xl font-semibold text-primary_color mb-5">
              Latest Blogs
            </h2>
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <ScaleLoader color="#852065" />
              </div>
            ) : blog.length > 0 ? (
              <div>
                {/* Show first 3 blogs */}
                <div className="grid grid-cols-1 gap-2">
                  {blog.slice(0, 3).map((item, index) => (
                    <a
                      key={index}
                      href={`/news/${item?._id}`}
                      className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                    >
                      {/* Card Image */}
                      <img
                        src={item?.image?.location}
                        alt="Blog"
                        className="w-full h-40 object-cover"
                      />
                      {/* Card Content */}
                      <div className="p-2 flex flex-col flex-grow">
                        {/* Title */}
                        <h3 className="text-sm text-left font-semibold text-gray-800 truncate">
                          {item?.title}
                        </h3>
                        {/* Description */}
                        <p className="text-[10px] text-left text-gray-600 mt-2 line-clamp-2">
                          {docData?.doctor_name} -{" "}
                          {new Date(item?.updatedAt).toLocaleDateString(
                            "en-IN"
                          )}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
                {/* View More Button */}
                {blog.length >= 3 && (
                  <div className="mt-4 text-center">
                    <a
                      href="/news"
                      className="text-primary_color hover:text-[#99467F] font-semibold"
                    >
                      View More
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center text-gray-500">
                <p>No blog available at the moment.</p>
              </div>
            )}
          </div>
        </div>

        <div className="w-full md:w-9/12 flex flex-col items-start gap-y-7">
          <div className="flex flex-col items-start gap-y-2">
            <h1 className="text-mainHeading text-primary_color font-medium">
              {docData?.doctor_name}
            </h1>
            <h2 className="text-xl">{docData?.qualification}</h2>
            <p className="text-left text-2xl font-light opacity-70 capitalize ">
              {docData?.title}—
              <br />
              {docData?.department_name
                ? docData?.department_name?.toLowerCase()
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col items-start w-full ">
            <h2 className="text-3xl font-normal">About</h2>
            <div className="h-[2px] w-full bg-primary_color"></div>
            <p className="md:mr-48 text-left text-lg text-[#6D6E71] pt-3">
              {docData?.about}
            </p>
          </div>

          <div className="flex flex-col items-start w-full">
            <h2 className="text-3xl font-normal">Experience</h2>
            <div className="h-[2px] w-full bg-primary_color"></div>
            <ul className="inline-block text-left text-lg text-[#6D6E71] exp-list pt-3">
              {docData?.experiences?.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-start w-full">
            <h2 className="text-3xl font-normal">Areas of Expertise</h2>
            <div className="h-[2px] w-full bg-primary_color"></div>
            <ul className="inline-block text-left text-lg text-[#6D6E71] aoe-list pt-3">
              {docData?.areas_of_expertise?.map((item, i) => (
                <li className="py-1" key={i}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-full mt-20" id="booking">
        <div className="md:w-3/12 flex flex-col md:items-start mb-5 md:mb-0">
          <h1 className="text-2xl font-medium md:font-semibold text-primary_color pb-2">
            Book Hospital Visit
          </h1>

          <p className="pt-5 text-sm text-[#6D6E71] mb-3">
            Select date and time
          </p>

          <button
            onClick={openBookModal}
            className="w-full md:w-1/2 border-none px-4 py-3 rounded-lg text-sm text-white bg-primary_color font-medium hover:scale-105 ease-in-out duration-300"
          >
            Book now
          </button>
        </div>

        <div className="md:w-9/12 border border-black rounded-xl flex h-fit overflow-clip">
          {!visitId && (
            <div className="w-2/12 grid grid-cols-1">
              {fDates?.map((item, i) => (
                <DateCard
                  key={i}
                  day={item.toString().split(" ")[0]}
                  month={item?.toString().split(" ")[1]}
                  num={item?.toString().split(" ")[2]}
                  date={item}
                  bookDate={bookDate}
                  setConsultObj={setConsultObj}
                  setBookDate={setBookDate}
                />
              ))}
            </div>
          )}

          <div className="w-full flex items-center flex-col p-5">
            {visitId ? (
              <div className="w-full flex items-center justify-between flex-col gap-y-2 relative ">
                <h2 className="text-xl ">Choose a Token</h2>
                <h2 className="text-lg  text-gray-500">Date : {bookDate}</h2>
                <hr className="h-[1.5px] w-full bg-black mt-2 mb-4" />
                <div className="flex flex-wrap justify-center sm:justify-between gap-6 sm:gap-3 w-full">
                  {loading ? (
                    <div className="w-full flex justify-center items-center">
                      <ScaleLoader color="#852065" />
                    </div>
                  ) : tokens?.length > 0 ? (
                    tokens?.map((item, i) => (
                      <TokenCard
                        key={i}
                        data={item}
                        tokenId={tokenId}
                        setTokenId={setTokenId}
                      />
                    ))
                  ) : (
                    <h2 className="w-full self-center">No Slots Available</h2>
                  )}
                </div>

                <button
                  onClick={ClearTokenAndSession}
                  className="absolute top-4 left-0 text-primary_color border border-primary_color p-2 rounded-lg"
                >
                  <FaArrowLeft />
                </button>
              </div>
            ) : (
              <div className="w-full h-full flex flex-col gap-5 justify-around">
                <h1 className="text-xl">Choose a Session</h1>

                <div className="w-full h-full flex flex-wrap items-center justify-around gap-4 ">
                  {loading ? (
                    <div className="w-full flex justify-center items-center">
                      <ScaleLoader color="#852065" />
                    </div>
                  ) : consultObj?.is_outofstation ? (
                    <p className="capitalize">{consultObj?.reason}</p>
                  ) : visits?.length > 0 ? (
                    visits?.map((item, i) => (
                      <div
                        onClick={() => setVisitId(item?.visit_id)}
                        key={i}
                        className="w-fit h-fit flex flex-col cursor-pointer border border-primary_color rounded-lg p-5"
                      >
                        <span>Session {i + 1}</span>
                        <span>
                          {" "}
                          {item.time_from} to {item?.time_to}{" "}
                        </span>
                        <span>Available Slots : {item?.slots_available} </span>
                      </div>
                    ))
                  ) : (
                    <p className="capitalize">
                      Doctor is not available on this date
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {open && (
        <BookAppoinment
          open={open}
          setOpen={setOpen}
          bookDate={bookDate}
          setBookDate={setBookDate}
          visitId={visitId}
          setVisitId={setVisitId}
          tokenId={tokenId}
          setTokenId={setTokenId}
          tommorrowDate={tommorrowDate}
          setOpenApptModal={setOpenApptModal}
          setApptData={setApptData}
        />
      )}

      {openApptModal && (
        <ApptModal
          open={openApptModal}
          setOpenApptModal={setOpenApptModal}
          data={apptData}
        />
      )}
    </div>
  );
};

export default DoctorProfile;

const DateCard = (props) => {
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleClick = () => {
    props?.setConsultObj({});
    props?.setBookDate(formatDate(props?.date));
  };

  const isActive = props.bookDate === formatDate(props?.date);

  return (
    <div
      onClick={handleClick}
      className={`flex flex-col items-center w-full  hover:bg-white hover:text-primary_color 
      cursor-pointer hover:ease-in-out duration-300   text-sm py-2 ${
        isActive
          ? "bg-white text-primary_color"
          : "bg-primary_color text-white border-b"
      } `}
    >
      <p className="uppercase">{props?.day}</p>
      <h5 className="text-2xl">{props?.num}</h5>
      <p className="uppercase">{props?.month}</p>
    </div>
  );
};

const TokenCard = ({ data, tokenId, setTokenId }) => {
  const isChosen = tokenId === data?.id;

  return (
    <div
      onClick={() => setTokenId(data?.id)}
      className={` hover:bg-primary_color hover:text-white 
    cursor-pointer rounded-lg px-3 py-1 flex flex-col ${
      isChosen
        ? "bg-primary_color text-white"
        : "text-primary_color border border-primary_color"
    }`}
    >
      <h3>Token {data?.token}</h3>
      <p className="text-sm ">
        {data?.time_from} to {data?.time_to}
      </p>
    </div>
  );
};
