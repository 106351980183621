import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Pagination = ({ page, setPage, entries, total }) => {
  const numbers = Array.from({ length: Math.ceil(total / entries) }, (_, i) => i + 1)

  const handlePrev = () => {
    if (page < 2) {
      return
    }

    setPage((prev) => (prev - 1))
  }

  const handleNext = () => {
    if (page >= Math.ceil(total / entries)) {
      return
    }
    setPage((prev) => (prev + 1))
  }

  return (
    <div className="flex items-center mt-1 mb-10 lg:ml-6 px-4 gap-2">
      {/* Previous Icon */}
      <button
        onClick={handlePrev}
        className={`w-8 h-8 border border-opacity-60 rounded-md ${page === 1 ? 'text-white bg-prev_gray' : 'text-gray'}
          flex items-center justify-center text-lg font-bold`}>
        <FaChevronLeft />
      </button>

      {/* Number Icons */}
      {numbers.map((number) => (
        <button
          onClick={() => setPage(number)}
          key={number}
          className={`w-8 h-8 border rounded-md text-black flex items-center
             justify-center text-lg font-bold ${number === page ? 'text-primary_color border-primary_color' : 'border-gray border-opacity-60'}`}
        >
          {number}
        </button>
      ))}

      {/* Next Icon */}
      <button
        onClick={handleNext}
        className={`w-8 h-8 border border-opacity-60 rounded-md
           ${page === Math.ceil(total / entries) ? 'text-white bg-prev_gray' : ' text-gray'}
        flex items-center justify-center text-lg font-bold`}>
        <FaChevronRight />
      </button>
    </div>
  );
}

export default Pagination;
