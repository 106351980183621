import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";

const SearchBar = ({search, setSearch, removeFilters, mode, setMode}) => {

  const handleToggle = () => {
    setMode((prev)=>{
      if(prev === "name"){
        return "department"
      }
      else{
        return "name"
      }
    })
  };

  return (
    <div className='flex flex-col lg:flex-row lg:justify-between lg:mb-4 mb-6 gap-4 lg:ml-4 px-4 lg:p-3'>
      <div>
        <form className="">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative flex items-center">
            <input
              type="text"
              id="default-search"
              className="w-full lg:w-[500px] p-4 lg:pr-10 text-sm text-gray-900 border border-gray 
              rounded-full placeholder-gray-400 "
              placeholder="Search for doctors and departments"
              value={search}
              onChange={(e)=> setSearch(e.target.value)}
            />
           
           <IoClose 
           size={26}
           onClick={removeFilters}
           className='absolute right-4 cursor-pointer '
           />

          </div>
        </form>
      </div>
      <div>
        <div className='w-full lg:w-auto flex justify-between lg:justify-start items-center'>
          <div className='flex items-center space-x-5 lg:pt-4 pt-0'>
          Sort by : 
          <span className={`text-sm ${mode === "name" ? 'text-primary_color' : 'text-gray'} `}> Name</span>
            {/* Second toggle */}
            <button
              onClick={handleToggle}
              className='w-12 h-6 flex items-center rounded-full bg-primary_color relative'
            >
              <div
                className={`w-5 h-5 bg-white rounded-full absolute transition-transform duration-300 transform ${
                  mode === "name" ? 'translate-x-1' : 'translate-x-6'
                }`}
              />
            </button>
            <span className={`text-sm ${mode === "name" ? 'text-gray' : 'text-primary_color'} `}>Department</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;



