import React, { useEffect, useState } from 'react'
import axios from '../api/axios';
import { bannerRoute } from '../utils/Endpoint';

const useBanner = (panel) => {
   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
      const [mobBanner, setMobBanner] = useState(null)
      const [deskBanner, setDeskBanner] = useState(null)
  
      const getBanner = async () => {
          try {
              const res = await axios.get(`${bannerRoute}?panel=${panel}`)
  
              if (res.status === 200) {
                  const result = res.data.banners;
  
                  const desktopBannerArr = result?.filter(item => item?.screenType === 'desktop')
                  const mobileBannerArr = result?.filter(item => item?.screenType === 'mobile')
  
                  setDeskBanner(desktopBannerArr[0])
                  setMobBanner(mobileBannerArr[0])
              }
          } catch (error) {
              console.log(error)
          }
      }
  
      useEffect(() => { getBanner() }, [isMobile, panel])
  
      useEffect(() => {
          const handleResize = () => {
              setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener("resize", handleResize);
          return () => window.removeEventListener("resize", handleResize);
      }, []);
  
      return isMobile ? mobBanner : deskBanner;
}

export default useBanner