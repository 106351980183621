import React from 'react';
import TopBanner from '../common/TopBanner';
import bannerfinddoctor from '../../assets/find-doctor/bannerfinddoctor.jpg'
import useBanner from '../../hooks/useBanner';

const TopPart = ({ onSearchBySymptomsClick }) => {
  const currentBanner = useBanner('our-team')

  return (
    <div className=''>
      <TopBanner title={currentBanner?.title ?? "Find a Doctor"}
       subTitle={currentBanner?.subtitle ?? 'Medical Departments & Centers'} 
       image={currentBanner?.image?.location ?? bannerfinddoctor} />
      
      {/* <div className='  pt-10 max-w-common mx-auto mb-10 px-4'>
        <div className='ml-auto'>
          <h4 className='text-right text-xs'>Don't know which doctor to consult?</h4>
          <h3
            className='text-primary_color text-right cursor-pointer'
            onClick={onSearchBySymptomsClick}
          >
            Search by symptoms
          </h3>
        </div>

      </div> */}
    </div>
  );
}

export default TopPart;


