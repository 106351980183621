import { Box, Modal } from '@mui/material';
import React, { useState } from 'react'
import DetailPage from '../bookAptModal/DetailPage';
import OtpPage from '../bookAptModal/OtpPage';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: 400,
    borderRadius: 2,
    p: 2,
};
function BookAppoinment({ open, setOpen, bookDate,visitId, tokenId,
    setBookDate, setVisitId, setTokenId, tommorrowDate, setOpenApptModal,
    setApptData
}) {

    const [verified, setVerified] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const [detail, setDetail] = useState({
        visit_id: visitId,
        token: tokenId,
        date: bookDate,
        mobile_number: '',
        otp: '',

        patient_name: '',
        email: '',
        age: '',
        gender: '',
        address: '',
    })

    const changeHandler = (e) => {
        setDetail((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    console.log(detail)

    const BackToIntialState = () => {
        setDetail((prev) => ({
            visit_id: '',
            token: '',
            date: '',
            mobile_number: '',
            otp: '',
            patient_name: '',
            email: '',
            op_number: '',
            age: '',
            gender: '',
            address: '',

        }))
        setVerified(false)
        setIsSent(false)
        setOpen(false)
        setBookDate(tommorrowDate())
        setVisitId(null)
        setTokenId(null)
    }

    return (
        <div className='w-full'>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    {
                        verified
                            ?
                            <DetailPage detail={detail} setDetail={setDetail} changeHandler={changeHandler} setOpen={setOpen} 
                            BackToIntialState={BackToIntialState} setOpenApptModal={setOpenApptModal}
                            setApptData={setApptData}
                            />
                            :
                            <OtpPage detail={detail} setDetail={setDetail} changeHandler={changeHandler}
                                setVerified={setVerified} isSent={isSent} setIsSent={setIsSent} />
                    }


                </Box>
            </Modal>
        </div>
    )
}

export default BookAppoinment
