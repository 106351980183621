import React, { useState } from "react";
import TopBanner from "../components/common/TopBanner";
import image from "../assets/contactus/contact_us_banner_bg.jpeg";
import { FaPhoneAlt } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";
import { toast } from "react-toastify";
import useBanner from "../hooks/useBanner";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "../api/axios";
import { inquiryRoute } from "../utils/Endpoint";


function ContactUs() {

  const initialData = {
    type: "feedback",
    name: "",
    email: "",
    phone: "",
    message: "",
  }

  const [formData, setFormData] = useState(initialData);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const RecaptchaKey = process.env.REACT_APP_G_SITE_KEY;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // re-captcha
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const envType = process.env.REACT_APP_ENV;

    if (envType === 'production' && !recaptchaToken) {
      return toast.error("Please complete the reCAPTCHA");
    }

    const nameRegex = /^[a-zA-Z\s'-]{2,50}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/;

    if (!nameRegex.test(formData?.name)) {
      return toast.info('Enter a valid name')
    }

    if (!emailRegex.test(formData?.email)) {
      return toast.info('Enter a valid email')
    }

    if (!mobileNumberRegex.test(formData?.phone)) {
      return toast.info('Enter a valid Phone number')
    }

    setLoading(true);

    try {
         const res = await axios.post(inquiryRoute, formData)
   
         if(res.status === 201){
           console.log("Email sent successfully!");
     
           setFormData(initialData);
     
           toast.success("Message Sent Successfully");
           setLoading(false);
   
         }
   
       } catch (error) {
         console.error("Error sending email:", error);
         toast.error("Please try after some time");
         setErrors((prev) => ({ ...prev, server: "Something went wrong" }));
         setLoading(false);
       }
  };

  const currentBanner = useBanner('contact-us')

  return (
    <div className="flex flex-col items-center">
      {/* ContactUs banner */}
      <TopBanner
        image={currentBanner?.image?.location ?? image}
        title={currentBanner?.title ?? "Contact Us"}
        subTitle={currentBanner?.title ?? "learn about our specialities"}
      />

      <div className="max-w-[1280px] mx-auto w-full flex items-center justify-center max-md:flex-col my-8 md:my-14 gap-x-7 text-paragraph font-medium">
        <div className="flex items-center gap-2">
          <MdAttachEmail className="text-primary_color" />
          <a href="mailto:youfirstalways@spmedifort.com">youfirstalways@spmedifort.com</a>
        </div>
        <div className="flex items-center gap-2">
          <FaPhoneAlt className="text-primary_color" />
          <a href="tel:+914713100100">+91 471 3100 100</a>

        </div>
      </div>

      {/* feedback form */}
      <div className=" w-full md:w-9/12 rounded-2xl border-b-[6px] border-primary_color flex md:pb-10 md:mb-20 p-3">
        <div className="md:w-[45%] w-full pr-10 max-md:pr-0 flex flex-col justify-start  items-start px-20 max-md:px-0 max-md:items-center">
          <h1 className="text-4xl font-semibold">
            Send Us <span className="text-primary_color">feedback</span>
          </h1>
          <form
            className="mt-6 items-start flex flex-col w-full "
            action=""
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData?.name}
              onChange={handleChange}
              required
              className="bg-[#E0E0E0]/50 w-full  px-6 p-2 rounded-lg mb-2 placeholder-[#5B5B5B]"
            />
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              required
              className="bg-[#E0E0E0]/50 w-full  px-6 p-2 rounded-lg mb-2 placeholder-[#5B5B5B]"
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email}</span>
            )}
            <input
              type="text"
              placeholder="Phone Number"
              name="phone"
              value={formData?.phone}
              onChange={handleChange}
              required
              className="bg-[#E0E0E0]/50 w-full  px-6 p-2 rounded-lg mb-2 placeholder-[#5B5B5B]"
            />
            {errors.phone && (
              <span style={{ color: "red" }}>{errors.message}</span>
            )}
            <textarea
              type="text"
              name="message"
              id=""
              value={formData?.message}
              onChange={handleChange}
              placeholder="Write your feedback here"
              className="bg-[#E0E0E0] w-full  px-6  rounded-lg h-[100px] inline-flex justify-start items-start pt-2 mb-2 placeholder-[#5B5B5B] resize-none"
            />

            <ReCAPTCHA sitekey={RecaptchaKey} onChange={handleRecaptchaChange} />

            <button 
            disabled={loading}
            type="submit" 
            className="mt-10 p-3 px-5 bg-primary_color rounded-lg text-white ">
              Submit
            </button>
          </form>
        </div>
        <div className="w-[55%] max-md:hidden">
          <img
            className="w-100 object-cover rounded-2xl"
            src={require("../assets/contactus/feedback.jpeg")}
            alt=""
          />
        </div>
      </div>

      {/* map */}
      <div className="mt-16 md:mt-24 flex flex-col  p-3 max-w-[1280px] mx-auto w-full  gap-y-6">
        <h1 className="text-mainHeading max-md:text-4xl font-medium">
          On the <span className="text-primary_color">Map</span>
        </h1>
        <div className="w-full h-[530px] border-b-[6px] border-primary_color rounded-2xl">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.6343967815437!2d76.92932490323453!3d8.484758499318044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bb8bd25bb37f%3A0xe6eb1debf8a21ac7!2sSP%20Medifort%20Hospital!5e0!3m2!1sen!2sin!4v1712857354566!5m2!1sen!2sin"
            className="w-full h-full rounded-2xl"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
