import { Box, Modal } from '@mui/material';
import React, { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: 400,
    borderRadius: 2,
    p: 2,
};

const ApptModal = ({ data, open , setOpenApptModal}) => {
    console.log(data)
    return (
        <div className='w-full'>

            <Modal
                open={open}
                onClose={()=> setOpenApptModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-col items-center justify-center gap-2 sm:gap-5'>
                        <h3 className='text-xl text-primary_color' >Appointment Details</h3>

                        <div className='flex flex-col items-start gap-3'>
                            <p className='capitalize'>
                                Name: 
                                <span className='text-primary_color'> {data?.patientName ?? 'NIL' } </span>
                            </p>
                            <p className='capitalize'>
                                Date: 
                                <span className='text-primary_color'> {data?.date ? data?.date?.split('T')[0] : 'NIL' } </span>
                            </p>
                            <p className=''>
                                From
                                <span className='text-primary_color'> {data?.timeFrom ?? 'NIL' } </span>
                                to
                                <span className='text-primary_color'> {data?.timeTo ?? 'NIL' } </span>
                            </p>
                            
                            <p className='capitalize'>
                                Amount: 
                                <span className='text-primary_color'> ₹{data?.amount ?? 'NIL' } </span>
                            </p>

                        </div>
                    </div>

                </Box>
            </Modal>
        </div>
    )
}

export default ApptModal
